import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { withContext } from './App';
import { withI18n } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import MicIcon from '@material-ui/icons/Mic';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
	spacer: {
		flex: "1 1 100%",
	},
	title: {
		flex: "0 0 auto",
	},
	fab: {
		position: 'fixed',
		bottom: theme.spacing.unit * 2,
		right: theme.spacing.unit * 2,
	},
	root: {
		width: '100%',
		overflowX: 'auto',
		overflowY: 'auto',
	},
	toolbar: {
	},
});

class VirtualAnalyst extends Component {

	// Event handlers

	// Life cycle methods

	componentDidMount() {
		
		var jwt = require("jsonwebtoken");
		
		var METABASE_SITE_URL = "http://localhost:3000";
		var METABASE_SECRET_KEY = "edce0a9f4d8c568a90298359637e4e123a5b2c72789b828e399ea5bba545c844";

		var payload = {
		  resource: { dashboard: 1 },
		  params: {}
		};
		var token = jwt.sign(payload, METABASE_SECRET_KEY);
		
		var iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=false&titled=false";

		this.setState({
			iframeUrl: iframeUrl,
		});
		
		console.log(iframeUrl);
	}
	
	// Other methods
	
	// Render
	
	render() {
		const { classes, t } = this.props;
		
		return this.state != null && (
			<>
				<Paper square>
					<Toolbar className={classes.toolbar}>
						<Typography variant="h6" className={classes.title} color="inherit" noWrap>{t('virtualAnalyst')}</Typography>
					</Toolbar>
				</Paper>
				<Paper square className={classes.root}>
					<iframe
							title={t('virtualAnalyst')}
						    src={this.state.iframeUrl}
						    frameborder="0"
						    width="100%"
						    height="800"
						    allowtransparency
					></iframe>
				</Paper>
				
				<Tooltip title={t('new')}>
					<Button 
							color="primary"
							className={classes.fab}
							variant="fab">
						<MicIcon/>
					</Button>
				</Tooltip>
			</>
		);
	}
}

VirtualAnalyst.propTypes = {
	context: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withContext(withI18n()(VirtualAnalyst)));

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import BackupIcon from '@material-ui/icons/Backup';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import UserIcon from '@material-ui/icons/AccountCircle';
import { AppContext, withContext } from './App';
import { Redirect } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withI18n } from 'react-i18next';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';
import ReactCountryFlag from 'react-country-flag';
import { withRouter } from 'react-router';

import VirtualAnalyst from './VirtualAnalyst';
import EntityList from './EntityList';
import EntityView from './EntityView';
import BackupTool from './BackupTool';
import history from './history';
import { localModel } from './localModel';
import Tooltip from '@material-ui/core/Tooltip';
import i18n from 'i18next';
import Paper from '@material-ui/core/Paper';

const drawerWidth = 240;

const styles = theme => ({
	root: {
		display: 'flex',
		minHeight: "100vh",
	},
	iframe: {
		width: '100%',
		height: '10000px',
		display: 'block',
		border: 'none',
	},
	button: {
	    marginRight: theme.spacing.unit * 2,
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginLeft: 12,
		marginRight: 36,
	},
	menuGroup: {
		textAlign: "right",
		paddingRight: "10px",
	},
	menuGroupClose: {
		display: "none",
	},
	menuButtonHidden: {
		display: 'none',
	},
	icon: {
		width: 140,
	},
	spacer: {
		flex: "1 1 100%",
	},
	title: {
		flexGrow: 1,
	},
	drawerPaper: {
		backgroundColor: "#F0F0F0",
		backgroundImage: "url(/menu-background.png)",
		position: 'relative',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		whiteSpace: 'nowrap',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing.unit * 7,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing.unit * 9,
		},
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		padding: theme.spacing.unit * 3,
		overflowX: 'auto',
	},
	chartContainer: {
		marginLeft: -22,
	},
	tableContainer: {
		height: 320,
	},
	h5: {
		marginBottom: theme.spacing.unit * 2,
	},
});

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.handleLogout = this.handleLogout.bind(this);
		this.handleSetSpanish = this.handleSetSpanish.bind(this);
		this.handleSetEnglish = this.handleSetEnglish.bind(this);
	}
	
	handleSetSpanish(event) {
		i18n.changeLanguage("es");
		this.setState({
			language: "es"
		});
	}
	
	handleSetEnglish(event) {
		i18n.changeLanguage("en");
		this.setState({
			language: "en"
		});
	}
	
	handleLogout(event) {
		this.props.context.setState({
			username: null,
			accessToken: null,
			model: null
		});
	}
	
	render() {
		const { classes, t } = this.props;
		
		const model = this.props.context.model;

		let entities = null;
		let menuGroups = {};
		
		if (this.props.context.model !== null) {
			const model = this.props.context.model;

			entities = Object.values(model.entities)
					.filter(entity => localModel.entities[entity.schema + "." + entity.name] && localModel.entities[entity.schema + "." + entity.name].menu)
					.concat(Object.keys(localModel.entities).filter(key => localModel.entities[key].external && localModel.entities[key].menu).map(key => { let item = localModel.entities[key]; item.schema = key.split(".")[0]; item.name = key.split(".")[1]; return item}))
					.sort((a, b) => localModel.entities[a.schema + "." + a.name].order - localModel.entities[b.schema + "." + b.name].order);
		}
		
		if (entities != null) {
			entities.forEach(entity => {
				menuGroups[entity.schema] = entity.schema;
			});
		}
		
		return (
			<div className={classes.root}>
				<CssBaseline />
				
				<AppContext.Consumer> 
					{(context) => (context.accessToken == null ? <Redirect to="/login"/> : null)}
				</AppContext.Consumer>
				
				<AppBar position="absolute" className={classNames(classes.appBar, this.props.context.open && classes.appBarShift)}>
					<Toolbar disableGutters={!this.props.context.open} className={classes.toolbar}>
						<IconButton
								color="inherit"
								aria-label="Open drawer"
								data-qa="open-drawer-button"
								onClick={this.props.context.handleDrawerOpen}
								className={classNames(
									classes.menuButton,
									this.props.context.open && classes.menuButtonHidden,
								)}>
							<MenuIcon />
						</IconButton>
						<Typography
								data-qa="title-label"
								variant="h6"
								color="inherit"
								noWrap
								className={classes.title}>
							{t('title')}
						</Typography>
						<Chip icon={<UserIcon/>} label={this.props.context.username} color="primary"/>
						<Tooltip title="Español">
							<IconButton data-qa="spanish-button" onClick={this.handleSetSpanish}>
								<ReactCountryFlag code="es" svg styleProps={{width: '20px', height: '20px'}}/>
							</IconButton>
						</Tooltip>
						<Tooltip title="English">
							<IconButton data-qa="english-button" onClick={this.handleSetEnglish}>
								<ReactCountryFlag code="us" svg styleProps={{width: '20px', height: '20px'}}/>
							</IconButton>
						</Tooltip>
						<Tooltip title={t("closeSession")}>
							<IconButton data-qa="logout-button" color="inherit" onClick={this.handleLogout}>
								<CloseIcon/>
							</IconButton>
						</Tooltip>
					</Toolbar>
				</AppBar>
				<Drawer
						variant="permanent"
						classes={{
							paper: classNames(classes.drawerPaper, !this.props.context.open && classes.drawerPaperClose),
						}}
						open={this.props.context.open}>
				
					<div className={classes.toolbarIcon}>
						<div style={{flex: "1 1 100%", textAlign: "center"}}>
							<img src="/customer-logo.png" className={classes.icon}/>
						</div>
						
						<IconButton data-qa="close-drawer-button" onClick={this.props.context.handleDrawerClose}>
							<ChevronLeftIcon/>
						</IconButton>
					</div>
					<List>
						{
						/*
						<ListItem key="VirtualAnalyst" button onClick={event => history.push("/admin")}>
							<Tooltip title={t("virtualAnalyst")}>
								<ListItemIcon>
									<Icon>assistant</Icon>
								</ListItemIcon>
							</Tooltip>
							<ListItemText primary={t("virtualAnalyst")}/>
						</ListItem>
						*/
						}
						{
							entities != null
									&& Object.values(menuGroups)
											.sort((a, b) => (a < b ? (-1) : (a > b ? 1 : 0)))
											.map(menuGroup => (
								<div key={menuGroup}>
									<Divider/>
									<Typography
											variant="overline"
											noWrap
											classes={{
												root: classNames(classes.menuGroup, !this.props.context.open && classes.menuGroupClose),
											}}>
										{menuGroup}
									</Typography>
									{
										entities
												.filter(entity => entity.schema === menuGroup)
												.map(entity => (
											<ListItem key={entity.schema + "." + entity.name} button onClick={event => history.push("/admin/" + entity.schema + "." + entity.name + (entity.external ? "/external" : ""))}>
												<Tooltip title={t("e." + entity.schema + "." + entity.name + ".pluralName")}>
													<ListItemIcon data-qa={entity.schema + "." + entity.name + "-menu"}>
														<Icon>{localModel.entities[entity.schema + "." + entity.name].icon}</Icon>
													</ListItemIcon>
												</Tooltip>
												<ListItemText primary={t("e." + entity.schema + "." + entity.name + ".pluralName")}/>
											</ListItem>
										))
									}
								</div>
							))
						}
						{
								model.super
									&&
							<div key="adminGroup">
								<Divider/>
								<Typography
										variant="overline"
										noWrap
										classes={{
											root: classNames(classes.menuGroup, !this.props.context.open && classes.menuGroupClose),
										}}>
									{t("adminGroup")}
								</Typography>
								<ListItem key="backup" button onClick={event => history.push("/tools/backup")}>
									<Tooltip title={t("backup")}>
										<ListItemIcon data-qa="backup-menu">
											<BackupIcon/>
										</ListItemIcon>
									</Tooltip>
									<ListItemText primary={t("backup")}/>
								</ListItem>
							</div>
						}
					</List>
				</Drawer>
				<main className={classes.content}>
					<div className={classes.appBarSpacer} />
					{
						(this.props.match.path === '/admin' 
								&& 
							<></>//<VirtualAnalyst/>
						)
						|| (this.props.match.path === '/tools/:toolName' 
								&& (
							(this.props.match.params.toolName === 'backup'
									&&
								<BackupTool/>
							))
						)
						|| (this.props.match.path === '/admin/:entityName' 
								&& 
							<EntityList main mode="edit" entity={this.props.match.params.entityName}/>
						)
						|| (this.props.match.path === '/admin/:entityName/external' 
								&& 
							<Paper square>
								<iframe className={classes.iframe} src={localModel.entities[this.props.match.params.entityName].url + "#access_token=" + this.props.context.accessToken} ></iframe>
							</Paper>
						)
						|| (this.props.match.path === '/admin/:entityName/new' 
								&& 
							<EntityView mode="edit" entity={this.props.match.params.entityName}/>
						)
						|| (this.props.match.path === '/admin/:entityName/new/:preselectedAttribute/:preselectedValue' 
								&& 
							<EntityView mode="edit" entity={this.props.match.params.entityName} preselectedAttribute={this.props.match.params.preselectedAttribute} preselectedValue={this.props.match.params.preselectedValue}/>
						)
						|| (this.props.match.path === '/admin/:entityName/:entityId/view' 
								&& 
							<EntityView mode="view" entity={this.props.match.params.entityName} entityId={this.props.match.params.entityId}/>
						)
						|| (this.props.match.path === '/admin/:entityName/:entityId/edit' 
								&& 
							<EntityView mode="edit" entity={this.props.match.params.entityName} entityId={this.props.match.params.entityId}/>
						)
					}
				</main>
			</div>
		);
	}
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(withContext(withI18n()(Dashboard))));

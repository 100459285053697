import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { withContext } from './App';
import { withI18n } from 'react-i18next';
import AsyncSelect from 'react-select/lib/Async';
import { localModel } from './localModel';

const styles = theme => ({
	root: {
		flexGrow: 1,
	},
	input: {
		display: 'flex',
		padding: 0,
		//height: "32px",   When I fix the height, it does not work if I add multiple lines of items.
	},
	valueContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		alignItems: 'center',
		overflow: 'hidden',
	},
	chip: {
		margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
	},
	chipFocused: {
		backgroundColor: emphasize (
			theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
			0.08,
		),
	},
	noOptionsMessage: {
		padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
	},
	singleValue: {
		fontSize: 16,
	},
	placeholder: {
		position: 'absolute',
		left: 2,
		fontSize: 16,
	},
	paper: {
		position: 'absolute',
		zIndex: 1,
		marginTop: theme.spacing.unit,
		left: 0,
		right: 0,
	},
});

function NoOptionsMessage(props) {
	return (
		<Typography
				color="textSecondary"
				className={props.selectProps.classes.noOptionsMessage}
				{...props.innerProps}>
			{props.children}
		</Typography>
	);
}

function inputComponent({ inputRef, ...props }) {
	return <div ref={inputRef} {...props} />;
}

function Control(props) {
	return (
		<TextField
				fullWidth
				InputProps={{
					inputComponent,
					inputProps: {
						className: props.selectProps.classes.input,
						inputRef: props.innerRef,
						children: props.children,
						...props.innerProps,
					},
				}}
			{...props.selectProps.textFieldProps}
		/>
	);
}

function Option(props) {
	return (
		<MenuItem
				buttonRef={props.innerRef}
				selected={props.isFocused}
				component="div"
				style={{
					fontWeight: props.isSelected ? 500 : 400,
				}}
				{...props.innerProps}>
			{props.children}
		</MenuItem>
	);
}

function Placeholder(props) {
	return (
		<Typography
				color="textSecondary"
				className={props.selectProps.classes.placeholder}
				{...props.innerProps}>
			{props.children}
		</Typography>
	);
}

function SingleValue(props) {
	return (
		<Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
			{props.children}
		</Typography>
	);
}

function ValueContainer(props) {
	return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
	return (
		<Chip
				tabIndex={-1}
				label={props.children}
				className={classNames(props.selectProps.classes.chip, {
					[props.selectProps.classes.chipFocused]: props.isFocused,
				})}
				onDelete={props.removeProps.onClick}
				deleteIcon={<CancelIcon {...props.removeProps} />}
		/>
	);
}

function Menu(props) {
	return (
		<Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
			{props.children}
		</Paper>
	);
}

class AutoComplete extends React.Component {
	constructor(props) {
		super(props);
		this.handleLoadOptions = this.handleLoadOptions.bind(this);
		this.refreshData = this.refreshData.bind(this);
	}
	
	getLabelAttributesQueryString(entityModel, entityLocalModel, exceptionAttribute, depth) {
		let str = "";

		if (depth == null) {
			depth = 1;
		}
		
		Object.values(entityModel.attributes)
			.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].label)
			.forEach(attribute => {
				if (exceptionAttribute == null || attribute.name != exceptionAttribute.name) {
					str += attribute.name + ", ";
				}
			});
		
		if (depth < 5) {
			Object.values(entityModel.references)
				.filter(reference => reference.referenceAttributeName != null && entityLocalModel.attributes[reference.referenceAttributeName].label)
				.forEach(reference => {
					const model = this.props.context.model;
					str += reference.referenceAttributeName + "{ " 
							+ this.getLabelAttributesQueryString(model.entities[reference.referencedKey.entityName], localModel.entities[reference.referencedKey.entityName], null, depth + 1)
							+ "}, ";
				});
		}
		
		return str;
	}
	
	getLabel(item, entityModel, entityLocalModel) {
		let label = "";
		
		if (item == null) {
			return null;
		}
		
		const model = this.props.context.model;

		let labels = Object.values(entityModel.attributes)
			.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].label)
			.map(attribute => {
				return {
					order: entityLocalModel.attributes[attribute.name].order,
					label: item[attribute.name],
				}
			});
		
		Object.values(entityModel.references)
			.filter(reference => reference.referenceAttributeName != null && entityLocalModel.attributes[reference.referenceAttributeName].label)
			.forEach(reference => {
				labels.push({
					order: entityLocalModel.attributes[reference.referenceAttributeName].order,
					label: this.getLabel(item[reference.referenceAttributeName], model.entities[reference.referencedKey.entityName], localModel.entities[reference.referencedKey.entityName])
				});
			});
		
		return labels.sort((a, b) => (a.order == null ? Infinity : a.order) - (b.order == null ? Infinity : b.order)).filter(label => label != null && label.label != null).map(label => label.label).join(", ");
	}
	
	refreshData(inputValue, resolve) {
		
		const model = this.props.context.model;
		
		const entityModel = model.entities[this.props.entityName];
		const entityLocalModel = localModel.entities[this.props.entityName];
		
		let searchAttributeNames = [];
		let searchCriteria = inputValue;
		
		searchCriteria = (searchCriteria == null || searchCriteria.replace(/[^Á-ÚA-Z0-9ñÑ\s]/gi, '').trim() === "" ? null : searchCriteria.replace(/[^Á-ÚA-Z0-9ñÑ\s]/gi, '').trim().split(/\s+/).join(":* &") + ":*");
		Object.values(entityModel.keys).filter(key => key.textSearch).map(key => Object.values(key.attributes).map(attribute => attribute.name in searchAttributeNames ? null : searchAttributeNames.push(attribute.name)));

		let keyAttribute = Object.values(entityModel.keys).filter(key => key.primaryKey)[0].attributes[0];
		
		const query = 
				'{ ' +
				'	result:' + this.props.entityName.replace(".", "_") + 'List(' +
				'		limit: 100' +
				(searchCriteria != null && searchAttributeNames.length > 0 ? '		where: {' + searchAttributeNames[0] + ': {SEARCH: {query: "' + searchCriteria + '" config: SPANISH}}}' : '') +
				'	) {' + keyAttribute.name + ',' +
				'   	' + this.getLabelAttributesQueryString(entityModel, entityLocalModel, keyAttribute) + 
				'	} ' +
				'}';

		const variables = {
    		authorization: this.props.context.accessToken
    	};
		let request = JSON.stringify({query: query, variables: variables});
		fetch(this.props.context.graphqlEndpoint, {
			method: "POST",
			body: request
		})
		.then(response => response.json())
		.then(json => {
			let result = [];
			json.data["result"].forEach(item => {
				let label = this.getLabel(item, entityModel, entityLocalModel);
				return result.push({value: item[keyAttribute.name], label: label})
			});
			resolve(result);
		});
	}
	
	handleLoadOptions(inputValue) {
		return new Promise(resolve => {
			clearTimeout(this.timeoutId);
			this.timeoutId = setTimeout(() => {
				this.refreshData(inputValue, resolve);
			}, 200);
		});
	}
	
	render() {
		const { classes, theme } = this.props;

		const selectStyles = {
			input: base => ({
				...base,
				color: theme.palette.text.primary,
				'& input': {
					font: 'inherit',
				},
			}),
		};
		const { t } = this.props;
		return (
			<div className={classes.root}>
				<AsyncSelect
						isClearable
						defaultOptions
						ref={this.props.asyncRef}
						loadOptions={this.handleLoadOptions}
						classes={classes}
						styles={selectStyles}
						autoFocus={this.props.autoFocus}
						isDisabled={this.props.disabled}
						loadingMessage={() => t('loading')}
						noOptionsMessage={() => t('noOptions')}
						textFieldProps={{
							required: this.props.required,
							disabled: this.props.disabled,
							label: this.props.label,
							InputLabelProps: {
								shrink: true,
							},
						}}
						components={{
							Control,
							Menu,
							MultiValue,
							NoOptionsMessage,
							Option,
							Placeholder,
							SingleValue,
							ValueContainer,
						}}
						placeholder={t('selectValue')}
				>
				</AsyncSelect>
			</div>
		);
	}
}

AutoComplete.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles,{withTheme:true})(withContext(withI18n()(AutoComplete)));

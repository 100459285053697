import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';  
import Dropzone from 'react-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Grid from '@material-ui/core/Grid';
import SnackbarContentWrapper from './SnackbarContentWrapper';
import PreviewList from './PreviewList';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
	'@keyframes progress': {
		'0%': {
			backgroundPosition: '0 0',
		},
		'100%': {
			backgroundPosition: '-70px 0',
		},
	},
	dropZone: {
		position: 'relative',
		width: '100%',
		minHeight: '100px',
		backgroundColor: '#F0F0F0',
		border: 'dashed',
		borderColor: '#C8C8C8',
		cursor: 'pointer',
		boxSizing: 'border-box',
	},
	stripes: {
		border: 'solid',
		backgroundImage: 'repeating-linear-gradient(-45deg, #F0F0F0, #F0F0F0 25px, #C8C8C8 25px, #C8C8C8 50px)',
		animation: 'progress 2s linear infinite !important',
		backgroundSize: '150% 100%',
	},
	rejectStripes: {
		border: 'solid',
		backgroundImage: 'repeating-linear-gradient(-45deg, #fc8785, #fc8785 25px, #f4231f 25px, #f4231f 50px)',
		animation: 'progress 2s linear infinite !important',
		backgroundSize: '150% 100%',
	},
	dropzoneTextStyle:{
		paddingLeft: theme.spacing.unit,
		paddingRight: theme.spacing.unit,
		paddingTop: theme.spacing.unit,
		textAlign: 'center',
	},
	dropzoneParagraph:{
		top: 0,
	}
});

class DropzoneArea extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openSnackBar: false,
			snackbarMessage: '',
			snackbarVariant: 'success'
		}
	}
	
	onDrop(files) {
		const _this = this;
		if(this.props.fileObjects.length + files.length > this.props.filesLimit) {
			this.setState({
				openSnackBar: true,
				snackbarMessage: `Maximum allowed number of files exceeded. Only ${this.props.filesLimit} allowed`, 
				snackbarVariant: 'error'
			});
		}
		else {
			var count = 0;
			var message = '';
			files.forEach((file) => {
				if(this.props.onDrop) {
					this.props.onDrop(file);
				}
				message += `File ${file.name} successfully uploaded. `;
				count++; // we cannot rely on the index because this is asynchronous
				if(count === files.length){
					// display message when the last one fires
					this.setState({
						openSnackBar: true,
						snackbarMessage: message, 
						snackbarVariant: 'success'
					});
				}
			});
		}
	}
	handleDownload = fileIndex => event => {
		event.stopPropagation();
		const {fileObjects} = this.props;
		const fileObject = fileObjects.filter((fileObject, i) => { return i === fileIndex})[0];
	    let link = document.createElement("a");

	    // Workaround to bypass 2MB Chrome's limit
	    var byteString = atob(fileObject.data.split(',')[1]);
	    var mimeString = fileObject.data.split(',')[0].split(':')[1].split(';')[0];
	    var ab = new ArrayBuffer(byteString.length);
	    var ia = new Uint8Array(ab);
	    for (var i = 0; i < byteString.length; i++) {
	        ia[i] = byteString.charCodeAt(i);
	    }
	    var blob = new Blob([ab], {type: mimeString});
	    let objectUrl = window.URL.createObjectURL(blob);
	    
	    link.download = fileObject.name;
	    //link.href = fileObject.data; // Bypass 2MB Chrome's limit
	    link.href = objectUrl;
	    document.body.appendChild(link);
	    link.click();
	    link.remove();
	}
	handleRemove = fileIndex => event => {
		event.stopPropagation();
		const {fileObjects} = this.props;
		const fileObject = fileObjects.filter((fileObject, i) => { return i === fileIndex})[0];
		fileObjects.splice(fileIndex, 1);
		if (this.props.onDelete) {
			this.props.onDelete(fileObject);
		}
		this.setState({
			openSnackBar: true,
			snackbarMessage: ('File ' + fileObject.name+ ' removed'),
			snackbarVariant: 'info'
		});
	}
	handleDropRejected(rejectedFiles, evt) {
		var message = '';
		rejectedFiles.forEach((rejectedFile) => {
			message = `File ${rejectedFile.name} was rejected. `;
			if(!this.props.acceptedFiles.includes(rejectedFile.type)){
				message += 'File type not supported. '
			}
			if (rejectedFile.size > this.props.fileSizeLimit) {
				let limit = Number((this.props.fileSizeLimit / Math.pow(1024, Math.floor(Math.log2(this.props.fileSizeLimit) / 10))).toFixed(2)) + [' bytes', ' kB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZB', ' YB'][Math.floor(Math.log2(this.props.fileSizeLimit) / 10)];
				message += 'File is too big. Size limit is ' + limit + '. ';
			}
		});
		if(this.props.onDropRejected){
			this.props.onDropRejected(rejectedFiles, evt);
		}
		this.setState({
			openSnackBar: true,
			snackbarMessage: message,
			snackbarVariant: 'error'
		});
	}
	onCloseSnackbar = () => {
		this.setState({
			openSnackBar: false,
		});
	};
	render(){
		const {classes} = this.props;
		return (
			<Fragment>
				<Dropzone
					disabled={this.props.disabled}
					accept={this.props.acceptedFiles.join(',')}
					onDrop={this.onDrop.bind(this)}
					onDropRejected={this.handleDropRejected.bind(this)}
					className={classNames(classes.dropZone,this.props.dropZoneClass)}
					acceptClassName={classes.stripes}
					rejectClassName={classes.rejectStripes}
					maxSize={this.props.maxFileSize}
				>
					<div className={classes.dropzoneTextStyle}>
						<Typography variant="button" 
							className={classes.dropzoneParagraph}
							color="inherit"
							noWrap
						>
							{this.props.dropzoneText}
						</Typography>
					</div>
					{	
						<PreviewList 
							disabled={this.props.disabled}
							fileObjects={this.props.fileObjects} 
							handleDownload={this.handleDownload.bind(this)}
							handleRemove={this.handleRemove.bind(this)}
						/>
					}
				</Dropzone>
				{
					this.props.showAlerts 
						&&
					<Snackbar
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						open={this.state.openSnackBar}
						autoHideDuration={6000}
						onClose={this.onCloseSnackbar}
					>
						<SnackbarContentWrapper
							onClose={this.onCloseSnackbar}
							variant={this.state.snackbarVariant}
							message={this.state.snackbarMessage}
						/>
					</Snackbar>              
				}
			</Fragment>
		)
	}
}

DropzoneArea.defaultProps = {
	acceptedFiles: ['image/*', 'video/*', 'application/*'],
	filesLimit: 5,
	maxFileSize: 25000000,
	dropzoneText: 'Drag and drop an image file here or click',
	showAlerts: true,
	clearOnUnmount: true,
	onDrop: () => {},
	onDropRejected: () => {},
	onDelete: () => {},
	fileObjects: [],
	disabled: false,
}

DropzoneArea.propTypes = {
	acceptedFiles: PropTypes.array,
	filesLimit: PropTypes.number,
	maxFileSize: PropTypes.number,
	dropzoneText: PropTypes.string,
	showAlerts: PropTypes.bool,
	clearOnUnmount: PropTypes.bool, 
	onDrop: PropTypes.func,
	onDropRejected: PropTypes.func,
	onDelete: PropTypes.func,
	fileObjects: PropTypes.array,
	disabled: PropTypes.bool,
}

export default withStyles(styles)(DropzoneArea);

import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withI18n } from 'react-i18next';
import i18n from 'i18next';
import ReactCountryFlag from 'react-country-flag';

const styles = theme => ({
	
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	appBar: {
		position: 'relative',
	},
	button: {
	    marginLeft: theme.spacing.unit,
	},
	cardHeader: {
		backgroundColor: theme.palette.grey[200],
	},
	cardPricing: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'baseline',
		marginBottom: theme.spacing.unit * 2,
	},
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	cardActions: {
		[theme.breakpoints.up('sm')]: {
			paddingBottom: theme.spacing.unit * 2,
		},
	},
	cardContent: {
		flexGrow: 1,
	},
	cardGrid: {
		padding: `${theme.spacing.unit * 2}px 0`,
	},
	cardMedia: {
		paddingTop: '56.25%', // 16:9
	},
	footer: {
		marginTop: theme.spacing.unit * 8,
		borderTop: `1px solid ${theme.palette.divider}`,
		padding: `${theme.spacing.unit * 6}px 0`,
	},
	heroContent: {
		maxWidth: 800,
		margin: '0 auto',
		padding: `${theme.spacing.unit * 5}px 0 ${theme.spacing.unit * 5}px`,
	},
	icon: {
		marginRight: theme.spacing.unit * 2,
	},
	layout: {
		width: 'auto',
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
			width: 900,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	toolbarTitle: {
		flex: 1,
	},
});

const cards = [1, 2, 3, 4, 5, 6, 7, 8];

const tiers = [
	{
		title: 'Gratis',
		price: '0',
		description: [
			'1 usuario incluido', 
			'Carta de hasta 100 platos',
			'Pedidos a domicilio (hasta 500 pedidos/mes)',
			'Soporte por email',
			'Cuadro de mando básico'],
		buttonText: 'Apúntate gratis',
		buttonVariant: 'outlined',
	},
	{
		title: 'Estándar',
		subheader: 'La opción más popular',
		price: '29,95',
		description: [
			'5 usuarios incluidos',
			'Carta de tamaño ilimitado',
			'Soporte por email',
			'Soporte telefónico 8x5',
			'Pedidos a domicilio (1€/pedido)',
			'Pagos integrados',
			'Cuadro de mando avanzado'
		],
		buttonText: 'Apúntate aquí',
		buttonVariant: 'contained',
	},
	{
		title: 'Cadenas de Restaurantes',
		price: '299,95',
		description: [
			'Usuarios ilimitados',
			'Multi-restaurante',
			'Carta de tamaño ilimitado',
			'Soporte por email',
			'Soporte telefónico 24x7',
			'Gestión de reservas',
			'Gestión de promociones',
			'Pedidos a domicilio (0,5€/pedido)',
			'Pedidos desde mesa del local',
			'Pagos integrados',
			'Cuadro de mando avanzado',
			'Acceso a API'
		],
		buttonText: 'Apúntate aquí',
		buttonVariant: 'outlined',
	},
];

const footers = [
	{
		title: 'Nosotros',
		description: ['Quiénes somos', 'Nuestra historia', 'Contacta con nosotros', 'Dónde estamos'],
	},
	{
		title: 'Características',
		description: ['Lo más novedoso', 'Características destacadas', 'Gestión de datos integral', 'Integraciones', 'Desarrolladores'],
	},
	{
		title: 'Recursos',
		description: ['Documentación', 'Tutoriales'],
	},
	{
		title: 'Legal',
		description: ['Política de privacidad', 'Términos de uso'],
	},
];

class Home extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			language: i18n.language,
		};
		this.handleSetSpanish = this.handleSetSpanish.bind(this);
		this.handleSetEnglish = this.handleSetEnglish.bind(this);
	}
	
	handleSetSpanish(event) {
		i18n.changeLanguage("es");
		this.setState({
			language: "es"
		});
	}
	
	handleSetEnglish(event) {
		i18n.changeLanguage("en");
		this.setState({
			language: "en"
		});
	}
	
	render() {
		const { classes, t } = this.props;

		return (
			<React.Fragment>
				<CssBaseline />
				<AppBar position="static" color="default" className={classes.appBar}>
					<Toolbar>
						<RestaurantIcon className={classes.icon}/>
						<Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
							{t('title')}
						</Typography>
						<Button onClick={this.props.onLoginClick}>
							{t('privateArea')}
						</Button>
						<ButtonBase onClick={this.handleSetSpanish} className={classes.button}><ReactCountryFlag code="es" svg/></ButtonBase>
						<ButtonBase onClick={this.handleSetEnglish} className={classes.button}><ReactCountryFlag code="us" svg/></ButtonBase>
					</Toolbar>
				</AppBar>
				<main className={classes.layout}>
					{/* Hero unit */}
					<div className={classes.heroContent}>
						<Typography variant="h2" align="center" color="textPrimary" gutterBottom>
							{'"' + t('invocation') + '"'}
						</Typography>
						<Typography variant="h6" align="center" component="p">
							¿Tienes un restaurante?
						</Typography>
						<Typography variant="h6" align="center" component="p">
							¿Te gustaría que tus clientes pudieran hacer pedidos, reservas y muchas más cosas utilizando su voz?
						</Typography>
						<Typography variant="h6" align="center" component="p">
							Bienvenido a El Restaurante, la plataforma de voz de VoiceUX que te trae nuevos clientes, habla con ellos y les proporciona una experiencia más natural que las webs o las apps.
						</Typography>
	
						<p/>
						<div className={classes.heroButtons}>
							<Grid container spacing={16} justify="center">
								<Grid item>
									<Button variant="contained" color="primary">
										Prueba nuestro asistente
									</Button>
								</Grid>
								{/*
								<Grid item>
									<Button variant="outlined" color="primary">
										Secondary action
									</Button>
								</Grid>
								*/}
							</Grid>
						</div>						
					</div>
					{/* End hero unit */}
	
					{/* Hero unit */}
					<div className={classes.heroContent}>
						<Typography variant="h2" align="center" color="textPrimary" gutterBottom>
							Beneficios de la plataforma
						</Typography>
						<Typography variant="h6" align="center" color="textSecondary" component="p">
							Con nuestra plataforma de voz, obtendrás los siguientes beneficios:
						</Typography>
						<div className={classNames(classes.layout, classes.cardGrid)}>
							<Grid container spacing={40}>
								{cards.map(card => (
									<Grid item key={card} sm={6} md={4} lg={3}>
					                	<Card className={classes.card}>
					                		<CardMedia
					                				className={classes.cardMedia}
					                				image="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22288%22%20height%3D%22225%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20288%20225%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_164edaf95ee%20text%20%7B%20fill%3A%23eceeef%3Bfont-weight%3Abold%3Bfont-family%3AArial%2C%20Helvetica%2C%20Open%20Sans%2C%20sans-serif%2C%20monospace%3Bfont-size%3A14pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_164edaf95ee%22%3E%3Crect%20width%3D%22288%22%20height%3D%22225%22%20fill%3D%22%2355595c%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2296.32500076293945%22%20y%3D%22118.8%22%3EThumbnail%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E" // eslint-disable-line max-len
					                				title="Image title"
					                		/>
					                		<CardContent className={classes.cardContent}>
					                    <Typography gutterBottom variant="h5" component="h2">
					                      Heading
					                    </Typography>
					                    <Typography>
					                      This is a media card. You can use this section to describe the content.
					                    </Typography>
					                  </CardContent>
					                  <CardActions>
					                    <Button size="small" color="primary">
					                      View
					                    </Button>
					                    <Button size="small" color="primary">
					                      Edit
					                    </Button>
					                  </CardActions>
					                </Card>
					              </Grid>
					            ))}
					          </Grid>
					        </div>				
					</div>
					{/* End hero unit */}
					
					{/* Hero unit */}
					<div className={classes.heroContent}>
						<Typography variant="h2" align="center" color="textPrimary" gutterBottom>
							Precios
						</Typography>
						<Typography variant="h6" align="center" color="textSecondary">
							Si tienes menos de 500 pedidos al mes, puedes utilizar nuestra plataforma totalmente gratis.
							Si necesitas atender más pedidos o quieres utilizar otras funcionalidades, elige uno de nuestros planes de pago, sin ningún compromiso de permanencia.
						</Typography>
					</div>
					{/* End hero unit */}
					
					<Grid container spacing={40} alignItems="flex-end">
						{tiers.map(tier => (
							// Enterprise card is full width at sm breakpoint
							<Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
								<Card>
									<CardHeader
											title={tier.title}
											subheader={tier.subheader}
											titleTypographyProps={{ align: 'center' }}
											subheaderTypographyProps={{ align: 'center' }}
											action={tier.title === 'Pro' ? <StarIcon /> : null}
											className={classes.cardHeader}
									/>
									<CardContent>
										<div className={classes.cardPricing}>
											<Typography variant="h3" color="textPrimary">
												{tier.price}€
											</Typography>
											<Typography variant="h6" color="textSecondary">
												/mo
											</Typography>
										</div>
										{tier.description.map(line => (
											<Typography variant="subtitle1" align="center" key={line}>
												{line}
											</Typography>
										))}
									</CardContent>
									<CardActions className={classes.cardActions}>
										<Button fullWidth variant={tier.buttonVariant} color="primary">
											{tier.buttonText}
										</Button>
									</CardActions>
								</Card>
							</Grid>
						))}
					</Grid>
				</main>
				{/* Footer */}
				<footer className={classNames(classes.footer, classes.layout)}>
					<Grid container spacing={32} justify="space-evenly">
						{footers.map(footer => (
							<Grid item xs key={footer.title}>
								<Typography variant="h6" color="textPrimary" gutterBottom>
									{footer.title}
								</Typography>
								{footer.description.map(item => (
									<Typography key={item} variant="subtitle1" color="textSecondary">
										{item}
									</Typography>
								))}
							</Grid>
						))}
					</Grid>
				</footer>
				{/* End footer */}
			</React.Fragment>
		);
	}
}

Home.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withI18n()(Home));


export const localModel = {
	"entities": {
		"Models.CustomType": {
			"menu": false,
			"icon": "receipt",
			"attributes": {
				"schema": {
					"order": 1,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"documentation": {
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
					"order": 3,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				"CustomTypeAttributeListViaContainer": {
					"order": 4,
				},
				"CustomTypeAttributeListViaCustomType": {
					"visible": false,
				},
				"EntityAttributeListViaCustomType": {
					"visible": false,
				},
				"CustomTypeLabelListViaContainer": {
					"order": 5,
				}
			}
		},
		
		"Models.CustomTypeAttribute": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"CustomTypeViaContainer": {
					"order": 1,
					"label": true,
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"type": {
					"order": 4,
					"xs": 12,
					"sm": 12,
				},
				"isArray": {
					"order": 11,
				},
				"customType": {
					"visible": false,
					"list": false,
				},
				"CustomTypeViaCustomType": {
					"order": 5,
					"list": false,
				},
				"enumType": {
					"visible": false,
					"list": false,
				},
				"EnumTypeViaEnumType": {
					"order": 6,
					"list": false,
				},
				"length": {
					"order": 15,
					"list": false,
					"min": 0,
				},
				"precision": {
					"order": 17,
					"list": false,
					"min": 1,
					"max": 1000,
				},
				"scale": {
					"order": 18,
					"list": false,
					"min": 0,
					"max": 1000,
				},
				"documentation": {
					"order": 12,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.CustomTypeAttributeLabel": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"CustomTypeAttributeViaContainer": {
					"order": 1,
					"label": true,
				},
				"locale": {
					"order": 2,
					"label": true,
				},
				"label": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.CustomTypeLabel": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"CustomTypeViaContainer": {
					"order": 1,
					"label": true,
				},
				"locale": {
					"order": 2,
				},
				"label": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.Entity": {
			"order": 1,
			"menu": true,
			"icon": "description",
			"attributes": {
				"schema": {
					"order": 1,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"menu": {
					"order": 5,
					"lastInRow": true,
				},
				"order": {
					"order": 6,
					"min": 1,
					"max": 1000,
				},
				"icon": {
					"order": 7,
					"list": false,
				},
				"documentation": {
					"order": 4,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"oid": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				"EntityAttributeListViaContainer": {
					"order": 8,
				},
				"EntityTabListViaContainer": {
					"order": 9,
				},
				"EntityGroupListViaContainer": {
					"order": 10,
				},
				"EntityKeyListViaContainer": {
					"order": 11,
				},
				"EntityReferenceListViaContainer": {
					"order": 12,
				},
				"EntityTriggerListViaContainer": {
					"order": 13,
				},
				"EntityLabelListViaContainer": {
					"order": 14,
				},
			}
		},
		
		"Models.EntityAttribute": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityViaContainer": {
					"order": 1,
					"label": true,
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"type": {
					"order": 4,
					"xs": 12,
					"sm": 12,
				},
				"isArray": {
					"order": 13,
				},
				"isPassword": {
					"order": 14,
				},
				"isEventStart": {
					"order": 15,
				},
				"isEventEnd": {
					"order": 16,
				},
				"isPointInMap": {
					"order": 17,
				},
				"isComputed": {
					"order": 18,
				},
				"isEncrypted": {
					"order": 19,
					"lastInRow": true,
				},
				"defaultValue": {
					"order": 36,
					"xs": 12,
					"sm": 12,
					"list": false,
				},
				"isRequired": {
					"order": 7,
				},
				"customType": {
					"visible": false,
					"list": false,
				},
				"CustomTypeViaCustomType": {
					"order": 5,
					"list": false,
				},
				"enumType": {
					"visible": false,
					"list": false,
				},
				"EnumTypeViaEnumType": {
					"order": 6,
					"list": false,
				},
				"length": {
					"order": 25,
					"list": false,
					"min": 0,
				},
				"pattern": {
					"order": 26,
					"list": false,
				},
				"precision": {
					"order": 28,
					"list": false,
					"min": 1,
					"max": 1000,
				},
				"scale": {
					"order": 29,
					"list": false,
					"min": 0,
					"max": 1000,
				},
				"isLabel": {
					"order": 8,
				},
				"isList": {
					"order": 9,
				},
				"isVisible": {
					"order": 10,
				},
				"isBasicFilter": {
					"order": 11,
				},
				"isLastInRow": {
					"order": 12,
				},
				"tab": {
					"visible": false,
					"list": false,
				},
				"EntityTabViaTab": {
					"order": 21,
				},
				"group": {
					"visible": false,
					"list": false,
				},
				"EntityGroupViaGroup": {
					"order": 22,
				},
				"order": {
					"order": 3,
					"min": 1,
					"max": 1000,
				},
				"xs": {
					"order": 23,
					"list": false,
					"min": 1,
					"max": 12,
				},
				"sm": {
					"order": 24,
					"list": false,
					"min": 1,
					"max": 12,
				},
				"min": {
					"order": 30,
					"list": false,
					"step": 0.000000000001,
				},
				"max": {
					"order": 31,
					"list": false,
					"step": 0.000000000001,
				},
				"step": {
					"order": 32,
					"xs": 12,
					"sm": 12,
					"list": false,
					"min": 0.000000000001,
					"step": 0.000000000001,
				},
				"isMultiline": {
					"order": 27,
					"list": false,
					"lastInRow": true,
				},
				"maxFiles": {
					"order": 34,
					"list": false,
					"min": 1,
				},
				"acceptedFileTypes": {
					"order": 33,
					"list": false,
				},
				"indexDocuments": {
					"order": 35,
					"list": false,
				},
				"documentation": {
					"order": 20,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"attnum": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				"EntityKeyAttributeListViaAttribute": {
					"visible": false,
				},
				"EntityReferenceAttributeListViaAttribute": {
					"visible": false,
				},
				"EntityAttributeLabelListViaContainer": {
					"order": 37,
				},
			}
		},
		
		"Models.EntityAttributeLabel": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityAttributeViaContainer": {
					"order": 1,
					"label": true,
				},
				"locale": {
					"order": 2,
					"label": true,
				},
				"label": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.EntityKey": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityViaContainer": {
					"order": 1,
					"label": true,
				},
				"schema": {
					"order": 2,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"name": {
					"order": 3,
					"label": true,
					"xs": 12,
					"sm": 12,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"isUnique": {
					"order": 4,
				},
				"isTextSearch": {
					"order": 5,
				},
				"isPrimaryKey": {
					"order": 6,
				},
				"documentation": {
					"order": 7,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				"EntityKeyAttributeListViaContainer": {
					"order": 8,
				},
				"EntityReferenceListViaReferencedKey": {
					"visible": false,
				}
			}
		},
		
		"Models.EntityKeyAttribute": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityKeyViaContainer": {
					"order": 1,
					"label": true,
				},
				"attribute": {
					"visible": false,
					"list": false,
				},
				"EntityAttributeViaAttribute": {
					"order": 2,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.EntityLabel": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityViaContainer": {
					"order": 1,
					"label": true,
				},
				"locale": {
					"order": 2,
					"label": true,
				},
				"label": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"pluralLabel": {
					"order": 4,
					"xs": 12,
					"sm": 12,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.EntityReference": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityViaContainer": {
					"order": 1,
					"label": true,
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"referencedKey": {
					"visible": false,
					"list": false,
				},
				"EntityKeyViaReferencedKey": {
					"order": 3,
				},
				"documentation": {
					"order": 4,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"isList": {
					"order": 5,
					"list": false,
					"xs": 12,
					"sm": 12,
				},
				"isVisible": {
					"order": 6,
					"list": false,
				},
				"listIsVisible": {
					"order": 7,
					"list": false,
				},
				"isLabel": {
					"order": 8,
					"list": false,
				},
				"listIsFilteredWhenEmpty": {
					"order": 9,
					"list": false,
				},
				"order": {
					"order": 10,
					"list": false,
				},
				"listOrder": {
					"order": 11,
					"list": false,
				},
				"tab": {
					"visible": false,
					"list": false,
				},
				"EntityTabViaTab": {
					"order": 12,
				},
				"listTab": {
					"visible": false,
					"list": false,
				},
				"EntityTabViaListTab": {
					"order": 13,
				},
				"group": {
					"visible": false,
					"list": false,
				},
				"EntityGroupViaGroup": {
					"order": 14,
				},
				"listGroup": {
					"visible": false,
					"list": false,
				},
				"EntityGroupViaListGroup": {
					"order": 15,
				},
				"xs": {
					"order": 16,
					"list": false,
				},
				"listXs": {
					"order": 17,
					"list": false,
				},
				"sm": {
					"order": 18,
					"list": false,
				},
				"listSm": {
					"order": 19,
					"list": false,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},				
				"EntityReferenceAttributeListViaContainer": {
					"order": 20,
				},
			}
		},
		
		"Models.EntityReferenceAttribute": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityReferenceViaContainer": {
					"order": 1,
					"label": true,
				},
				"attribute": {
					"visible": false,
					"list": false,
				},
				"EntityAttributeViaAttribute": {
					"order": 2,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.EnumType": {
			"order": 2,
			"menu": true,
			"icon": "list",
			"attributes": {
				"schema": {
					"order": 1,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"values": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"documentation": {
					"order": 4,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"oid": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				"EntityAttributeListViaEnumType": {
					"visible": false,
				},
				"CustomTypeAttributeListViaEnumType": {
					"visible": false,
				},
				"EnumTypeLabelListViaContainer": {
					"order": 5,
				},
			}
		},
		
		"Models.EnumTypeLabel": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EnumTypeViaContainer": {
					"order": 1,
					"label": true,
				},
				"locale": {
					"order": 2,
					"label": true,
				},
				"labels": {
					"order": 3,
					"xs": 12,
					"sm": 12,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},

		"Models.EntityTab": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityViaContainer": {
					"order": 1,
					"label": true,
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"order": {
					"order": 3,
					"min": 1,
					"max": 1000,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				"EntityTabLabelListViaContainer": {
					"order": 24,
				},
			}
		},
		
		"Models.EntityTabLabel": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityTabViaContainer": {
					"order": 1,
					"label": true,
				},
				"locale": {
					"order": 2,
					"label": true,
				},
				"label": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.EntityGroup": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityViaContainer": {
					"order": 1,
					"label": true,
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"order": {
					"order": 3,
					"min": 1,
					"max": 1000,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				"EntityGroupLabelListViaContainer": {
					"order": 24,
				},
			}
		},
		
		"Models.EntityGroupLabel": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityGroupViaContainer": {
					"order": 1,
					"label": true,
				},
				"locale": {
					"order": 2,
					"label": true,
				},
				"label": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.Role": {
			"order": 5,
			"menu": true,
			"icon": "lock",
			"attributes": {
				"name": {
					"order": 1,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"documentation": {
					"order": 2,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"oid": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				/*
				"EntityPermissionListViaRole": {
					"order": 3,
				},
				"EntityAttributePermissionListViaRole": {
					"order": 4,
				},
				"FilterByCurrentUserListViaRole": {
					"order": 5,
				},
				*/
				"UserRoleListViaRole": {
					"order": 6,
				},
			}
		},
		
		/*
		"Models.EntityPermission": {
			"attributes": {
				"role": {
					"visible": false,
					"list": false,
				},
				"RoleViaRole": {
					"order": 1,
					"label": true,
				},
				"entity": {
					"visible": false,
					"list": false,
				},
				"EntityViaEntity": {
					"order": 2,
					"label": true,
				},
				"types": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.EntityAttributePermission": {
			"attributes": {
				"role": {
					"visible": false,
					"list": false,
				},
				"RoleViaRole": {
					"order": 1,
					"label": true,
				},
				"attribute": {
					"visible": false,
					"list": false,
				},
				"EntityAttributeViaAttribute": {
					"order": 2,
					"label": true,
				},
				"types": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.FilterByCurrentUser": {
			"attributes": {
				"role": {
					"visible": false,
					"list": false,
				},
				"RoleViaRole": {
					"order": 1,
					"label": true,
				},
				"entity": {
					"visible": false,
					"list": false,
				},
				"EntityViaEntity": {
					"order": 2,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"path": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"pattern": "[_A-Za-z][_0-9A-Za-z\\.]*",
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		*/
		
		"Models.User": {
			"order": 6,
			"menu": true,
			"icon": "person",
			"attributes": {
				"username": {
					"order": 1,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"password": {
					"order": 2,
					"list": false,
					"password": true,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"oid": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				"UserRoleListViaUser": {
					"order": 3,
				},
			}
		},
		
		"Models.UserRole": {
			"attributes": {
				"role": {
					"visible": false,
					"list": false,
				},
				"RoleViaRole": {
					"order": 1,
					"label": true,
				},
				"user": {
					"visible": false,
					"list": false,
				},
				"UserViaUser": {
					"order": 2,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.Function": {
			"order": 4,
			"menu": true,
			"icon": "functions",
			"attributes": {
				"schema": {
					"order": 1,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"language": {
					"order": 3,
				},
				"contents": {
					"order": 4,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"cronExpression": {
					"order": 5,
				},
				"isHttpEnabled": {
					"order": 6,
				},
				"documentation": {
					"order": 7,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				"EntityTriggerListViaFunction": {
					"order": 8,
				},
			}
		},
		
		"Models.EntityTrigger": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityViaContainer": {
					"order": 1,
					"label": true,
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"moment": {
					"order": 3,
					"xs": 12,
					"sm": 12,
				},
				"events": {
					"order": 4,
					"xs": 12,
					"sm": 12,
				},
				"each": {
					"order": 5,
					"xs": 12,
					"sm": 12,
				},
				"function": {
					"visible": false,
					"list": false,
				},
				"FunctionViaFunction": {
					"order": 6,
					"list": false,
				},
				"condition": {
					"order": 7,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.ExternalEntity": {
			"order": 3,
			"menu": true,
			"icon": "exit_to_app",
			"attributes": {
				"schema": {
					"order": 1,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"url": {
					"order": 3,
					"sm": 12,
				},
				"menu": {
					"order": 5,
					"lastInRow": true,
				},
				"order": {
					"order": 6,
					"min": 1,
					"max": 1000,
				},
				"icon": {
					"order": 7,
					"list": false,
				},
				"documentation": {
					"order": 4,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				"ExternalEntityLabelListViaContainer": {
					"order": 14,
				},
			}
		},
		
		"Models.ExternalEntityLabel": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"ExternalEntityViaContainer": {
					"order": 1,
					"label": true,
				},
				"locale": {
					"order": 2,
					"label": true,
				},
				"label": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"pluralLabel": {
					"order": 4,
					"xs": 12,
					"sm": 12,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
	},
};

import { throttle, debounce } from "throttle-debounce";
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { withContext } from './App';
import { withI18n } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import BackupIcon from '@material-ui/icons/Backup';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import history from './history';
import Dropzone from 'react-dropzone';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
	title: {
		flexGrow: 1,
	},
	root: {
		width: '100%',
		overflowX: 'auto',
		overflowY: 'auto',
		padding: theme.spacing.unit * 3,
	},
	toolbar: {
	},
	snackbar: {
	},
	snackbarError: {
		backgroundColor: theme.palette.error.dark,
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		fontSize: 20,
		marginRight: theme.spacing.unit,
	},
	
	'@keyframes progress': {
		'0%': {
			backgroundPosition: '0 0',
		},
		'100%': {
			backgroundPosition: '-70px 0',
		},
	},
	dropZone: {
		position: 'relative',
		width: '100%',
		minHeight: '100px',
		backgroundColor: '#F0F0F0',
		border: 'dashed',
		borderColor: '#C8C8C8',
		cursor: 'pointer',
		boxSizing: 'border-box',
	},
	stripes: {
		border: 'solid',
		backgroundImage: 'repeating-linear-gradient(-45deg, #F0F0F0, #F0F0F0 25px, #C8C8C8 25px, #C8C8C8 50px)',
		animation: 'progress 2s linear infinite !important',
		backgroundSize: '150% 100%',
	},
	rejectStripes: {
		border: 'solid',
		backgroundImage: 'repeating-linear-gradient(-45deg, #fc8785, #fc8785 25px, #f4231f 25px, #f4231f 50px)',
		animation: 'progress 2s linear infinite !important',
		backgroundSize: '150% 100%',
	},
	dropzoneTextStyle:{
		paddingLeft: theme.spacing.unit,
		paddingRight: theme.spacing.unit,
		paddingTop: theme.spacing.unit,
		textAlign: 'center',
	},
	dropzoneParagraph:{
		top: 0,
	},
});

class BackupTool extends Component {
	
	constructor(props) {
		super(props);
		// console.log(">> BackupTool.constructor");
		
		this.state = {
			restoreConfirmationDialogOpened: false,
		};
		
		this.handleDownloadBackupClick = this.handleDownloadBackupClick.bind(this);
		this.handleFileDrop = this.handleFileDrop.bind(this);
		this.handleRestore = this.handleRestore.bind(this);
	}
	
	// Event handlers
	handleDownloadBackupClick(event) {
		this.props.context.showActivityIndicator();
		let filename = null;
		fetch("/backup", {
			method: "GET",
			headers: {
				"Authorization": "Basic " + this.props.context.accessToken,
			},
		})
		.then(response => {
			filename = response.headers.get("Content-Disposition").split(";")[1].split("=")[1].slice(1, -1);
			return response.blob();
		})
		.then(blob => {
			var url = window.URL.createObjectURL(blob);
			var a = document.createElement('a');
			a.href = url;
			a.download = filename;
			document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
			a.click();    
			a.remove();  //afterwards we remove the element again         
			this.props.context.hideActivityIndicator();
		});
	}

	handleFileDrop(files) {
		if (files != null && files.length == 1) {
			this.setState({
				file: files[0],
				restoreConfirmationDialogOpened: true,
			});
		}
		else {
			this.setState({
				message: this.props.t('restoreOnlyOneFile'),
				messageError: true,
				messageOpened: true,
			});
		}
	}
	
	handleRestore() {
		this.props.context.showActivityIndicator();
		
		var request = new XMLHttpRequest();
		
		request.upload.onprogress = function(event) {
			if (event.lengthComputable) {
				thisInstance.setState({
					message: Math.round(100 * event.loaded / event.total) + "%",
					messageError: false,
					messageOpened: true,
				});
			}
		};

		let thisInstance = this;
		
		request.onloadend = function() {
			thisInstance.setState({
				message: thisInstance.props.t('restoreSuccess'),
				messageError: false,
				messageOpened: true,
				restoreConfirmationDialogOpened: false,
			});
			thisInstance.props.context.hideActivityIndicator();
		}

		request.open("POST", "/restore");
		request.setRequestHeader("Authorization", "Basic " + this.props.context.accessToken);

		request.send(this.state.file);
	}
	
	// Render
	
	render() {
		// console.log(">> BackupTool.render");
		
		const { classes, t } = this.props;
		
		return this.props.context.model !== null && (
			<>
				<Paper square>
					<Toolbar className={classes.toolbar}>
						<Typography variant="h6" className={classes.title} color="inherit" noWrap>{t('backup')}</Typography>
					</Toolbar>
				</Paper>
				<Paper square className={classes.root}>
					<Grid container spacing={24}>
						<Grid item xs={12}>
							<Typography variant="subtitle2" 
									color="inherit" 
									noWrap>
								{t('backupBackup')}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Button variant="outlined" color="primary" onClick={this.handleDownloadBackupClick}>{t('downloadBackup')}</Button>
						</Grid>
						
						<Grid item xs={12}>
							<Typography variant="subtitle2" 
									color="inherit" 
									noWrap>
								{t('backupRestore')}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Dropzone
									accept={"application/sql"}
									className={classes.dropZone}
									acceptClassName={classes.stripes}
									rejectClassName={classes.rejectStripes}
									onDrop={this.handleFileDrop}
							>
								<div className={classes.dropzoneTextStyle}>
									<Typography variant="button" 
										className={classes.dropzoneParagraph}
										color="inherit"
										noWrap
									>
										{this.props.t('uploadBackup')}
									</Typography>
								</div>
							</Dropzone>
						</Grid>
					
					</Grid>
				</Paper>
				<Dialog style={{zIndex: 10000}}
						open={this.state && this.state.restoreConfirmationDialogOpened}
						onClose={event => this.setState({restoreConfirmationDialogOpened: false})}>
					<DialogTitle>{t('restoreConfirmation')}</DialogTitle>
					<DialogContent>
						<DialogContentText>{t('restoreQuestion')}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={event => this.setState({restoreConfirmationDialogOpened: false})}>{t('cancel')}</Button>
						&nbsp;
						<Button color="primary" onClick={this.handleRestore}>{t('restore')}</Button>
					</DialogActions>
				</Dialog>
				<Snackbar
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						autoHideDuration={5000}
						onClose={event => this.setState({ messageOpened: false })}
						open={this.state && this.state.messageOpened}>
					<SnackbarContent
							className={this.state.messageError ? classes.snackbarError : classes.snackbar}
							message={<><span className={classes.message}>{this.state.messageError && <ErrorIcon className={classes.icon}/>}{this.state.message}</span></>}
					/>
				</Snackbar>
			</>
		);
	}
}

BackupTool.propTypes = {
	context: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withContext(withI18n()(BackupTool)));

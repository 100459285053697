import { throttle } from "throttle-debounce";
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { withContext } from './App';
import { withI18n } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { localModel } from './localModel';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import SaveIcon from '@material-ui/icons/Save';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/EditOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import history from './history';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EntityList from './EntityList';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import AutoComplete from './AutoComplete';
import ChipInput from 'material-ui-chip-input';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FilePond, File, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PasswordField from 'material-ui-password-field';
import DropzoneArea from './DropzoneArea';
import SignaturePad from 'react-signature-pad-wrapper'

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType, FilePondPluginImagePreview);

const styles = theme => ({
	spacer: {
		flex: "1 1 auto",
	},
	title: {
		flex: "1 1 auto",
	},
	paper: {
		padding: theme.spacing.unit * 2,
		[theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
			padding: theme.spacing.unit * 3,
		},
	},
	stepper: {
		padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
	},
	tabsRoot: {
		//marginBottom: theme.spacing.unit * 1,
	},
	tabRoot: {
		textTransform: "none",
	},
	formGroup: {
		paddingTop: theme.spacing.unit * 2,
		borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
	},
	quill: {
		paddingTop: "24px",
	},
	snackbar: {
	},
	snackbarError: {
		backgroundColor: theme.palette.error.dark,
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		fontSize: 20,
		marginRight: theme.spacing.unit,
	},
	hidden: {
		display: 'none',
	},
	toolbar: {
	},
	alignButton: {
		position: "absolute",
	},
	dashedBorder: {
		border: "dashed",
		borderColor: "#C8C8C8",
	},
	inputLabel: {
		fontSize: "12px",
	},
});

class EntityView extends Component {

	constructor(props) {
		super(props);
		// console.log(">> EntityView.constructor");

		this.state = {
			currentEntity: null,
			data: null,
			uploadedData: {},  // Object containing uploaded data (bytea) for each attribute of type "bytea".
		};
		
		this.refreshData = this.refreshData.bind(this);
		this.handleEditClick = this.handleEditClick.bind(this);
		this.handleCancelClick = this.handleCancelClick.bind(this);
		this.handleSaveClick = this.handleSaveClick.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleChangeSelectedTab = this.handleChangeSelectedTab.bind(this);
		this.getVariableValue = this.getVariableValue.bind(this);
		this.handleAddFile = this.handleAddFile.bind(this);
		this.handleRemoveFile = this.handleRemoveFile.bind(this);
		this.handleDropDocument = this.handleDropDocument.bind(this);
		this.handleDeleteDocument = this.handleDeleteDocument.bind(this);
		this.handleAddChip = this.handleAddChip.bind(this);
		this.handleDeleteChip = this.handleDeleteChip.bind(this);
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
		
		this.refreshDataThrottled = throttle(500, this.refreshData);
	}
	
	// Event handlers
	
	handleChangeSelectedTab(event, value) {
		this.setState({
			selectedTab: value,
		});
	}
	
	handleCheckboxChange(event, checked, attribute) {
		//console.log(">> EntityView.handleDeleteChip")
		this.setState((state, props) => {
			state.data[attribute.name] = checked;
			return {
				data: state.data,
			};
		});
	}
	
	handleAddChip(chip, attribute) {
		//console.log(">> EntityView.handleAddChip")
		if (attribute.pattern == null
				|| attribute.pattern == ""
				|| new RegExp(attribute.pattern).test(chip)) {
		
			this.setState((state, props) => {
				if (state.data == null) {
					state.data = [{}];
				}
				if (state.data[attribute.name] == null) {
					state.data[attribute.name] = [];
				}
				state.data[attribute.name].push(chip);
				return {
					data: state.data,
				};
			});
		}
		else {
			this.setState({
				message: this.props.t('invalidValue'),
				messageError: true,
				messageOpened: true,
			});
		}
	}
	
	handleDeleteChip(chip, index, attribute) {
		//console.log(">> EntityView.handleDeleteChip")
		this.setState((state, props) => {
			state.data[attribute.name].splice(index, 1);
			return {
				data: state.data,
			};
		});
	}
	
	handleKeyDown(event) {
		if (event.keyCode === 27) {
			this.handleCancelClick(event);
		}
	}
	
	handleEditClick(event) {
		history.push("/admin/" + this.props.entity + '/' + this.props.entityId + "/edit");
	}
	
	handleCancelClick(event) {
		history.goBack();
	}
	
	getVariableType(attribute) {
		const entityLocalModel = localModel.entities[this.props.entity];
		
		let type = "";
		type += (attribute.array ? "[" : "");
		if (attribute.enumType != null) {
			type += attribute.enumType.schema 
					+ '_' 
					+ attribute.enumType.name.substr(0, 1).toUpperCase() 
					+ attribute.enumType.name.substr(1) 
					+ 'EnumType';
		}
		else {
			switch(attribute.type) {
				case "INTEGER":
				case "SMALLINT":
				case "BIGINT":
				case "SERIAL":
				case "SMALLSERIAL":
				case "BIGSERIAL":
					type += "Int";
					break;
				
				case "BOOLEAN":
					type += "Boolean";
					break;
				
				case "DECIMAL":
				case "DOUBLE_PRECISION":
				case "REAL":
				case "MONEY":
					type += "Float";
					break;
				
				default:
					if (entityLocalModel.attributes[attribute.name].type == "DOCUMENT") {
						type += "Models_DocumentTypeInputType";
					}
					else {
						type += "String";
					}
			}
		}

		type += (attribute.array ? "]" : "");
		type += (attribute.required ? "!" : "");
		return type;
	}
	
	getVariableValue(attribute) {
		//console.log(">>>> " + attribute.name);
		const entityLocalModel = localModel.entities[this.props.entity];
		
		switch(attribute.type) {
			case "INTEGER":
			case "SMALLINT":
			case "BIGINT":
			case "SERIAL":
			case "SMALLSERIAL":
			case "BIGSERIAL":
				if (attribute.referenceAttributeName === undefined) {
					return this.inputRefs[attribute.name].current.valueAsNumber;
				}
				else {
					return (this.inputRefs[attribute.name].current.select.state.value == null ? null : this.inputRefs[attribute.name].current.select.state.value.value);
				}
			
			case "BOOLEAN":
				return this.inputRefs[attribute.name].current.checked;
			
			case "DECIMAL":
			case "DOUBLE_PRECISION":
			case "REAL":
			case "MONEY":
				return this.inputRefs[attribute.name].current.valueAsNumber;
			
			case "BYTEA":
				const value = this.state.uploadedData[attribute.name];
				if (value == null) {
					return null;
				}
				else {
					if (attribute.array) {
						return value.map(item => JSON.stringify(item));
					}
					else {
						return JSON.stringify(value);
					}
				}

			default:
				if (entityLocalModel.attributes[attribute.name].type == "DOCUMENT") {
					if (attribute.array) {
						if (this.state.uploadedData[attribute.name] == null || this.state.uploadedData[attribute.name].length === 0) {
							return null;
						}
						else {
							return this.state.uploadedData[attribute.name];
						}
					}
					else {
						if (this.state.uploadedData[attribute.name] == null || this.state.uploadedData[attribute.name].length === 0) {
							return null;
						}
						else {
							return this.state.uploadedData[attribute.name][0];
						}
					}
				}
				else if (entityLocalModel.attributes[attribute.name].type == "SIGNATURE") {
					let signatureData = this.inputRefs[attribute.name].current.toData();
					if (signatureData != null && signatureData.length > 0) {
						return JSON.stringify(signatureData);
					}
					else {
						return null;
					}
				}
				else if (attribute.array 
						&& (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR")
						&& attribute.enumType == null) {
					if (this.state.data != null
							&& this.state.data[attribute.name] != null
							&& this.state.data[attribute.name].length > 0) {
						return this.state.data[attribute.name];
					}
					else {
						return null;
					}
				}
				else if (attribute.array 
						&& (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR")
						&& attribute.enumType != null) {

					return Object.values(this.inputRefs[attribute.name].current.options).filter(option => option.selected).map(option => option.value);
				}
				else {
					if (attribute.referenceAttributeName === undefined) {
						return this.inputRefs[attribute.name].current.value;
					}
					else {
						return (this.inputRefs[attribute.name].current.select.state.value == null ? null : this.inputRefs[attribute.name].current.select.state.value.value);
					}
				}
		}
	}
	
	handleSaveClick(event) {
		//console.log(">> EntityView.handleSaveClick")
		event.preventDefault();
		
		this.props.context.showActivityIndicator();
		
		const model = this.props.context.model;
		const attributes = this.state.attributes;
		
		//console.log("Attributes:");
		//console.log(attributes);
		const entityLocalModel = localModel.entities[this.props.entity];
		
		if (this.props.entityId !== undefined) {
			const query = 
					'mutation Update($' + this.state.keyAttribute.name + ': ' + this.getVariableType(this.state.keyAttribute) + " "
							+ attributes
									.filter(attribute => attribute.incomingReferenceAttributeName === undefined
											&& !attribute.computed
											&& !(!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined))))

									.map(attribute => '$' 
											+ attribute.name 
											+ ': ' 
											+ this.getVariableType(attribute)
									)
									.join(" ") + ') { ' +
					'	result: ' + this.props.entity.replace(".", "_") + 'Update(' +
					'		where: {' +
					'			' + this.state.keyAttribute.name + ': {EQ: $' + this.state.keyAttribute.name + '}' +
					'		}' +
					'		entity: {' + 
					attributes
							.filter(attribute => attribute.incomingReferenceAttributeName === undefined
									&& !attribute.computed
									&& !(!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined))))
							.map(attribute => attribute.name + ': $' + attribute.name).join(" ") +
					'		}' +
					'	) {' +
					'		' + this.state.keyAttribute.name + 
					'	}' +
					'}';
			
			//console.log("Query:");
			//console.log(query);
			
			const variables = {
	    		authorization: this.props.context.accessToken,
	    	};
			variables[this.state.keyAttribute.name] = parseInt(this.props.entityId);
			
			//console.log("InputRefs:");
			//console.log(this.inputRefs);
			
			attributes
					.filter(attribute => (attribute.incomingReferenceAttributeName === undefined
							&& !attribute.computed
							&& ((attribute.array && attribute.type === 'TEXT')
								|| entityLocalModel.attributes[attribute.name].type == "DOCUMENT"
								|| (this.inputRefs[attribute.name] !== null 
									&& this.inputRefs[attribute.name].current !== null 
									&& (this.inputRefs[attribute.name].current.type === 'checkbox' 
										|| this.inputRefs[attribute.name].current.value !== "")))))
					.forEach(attribute => variables[attribute.name] = this.getVariableValue(attribute));
			
			//console.log("Variables:");
			//console.log(JSON.stringify(variables));
			
			let request = JSON.stringify({query: query, variables: variables});
			fetch(this.props.context.graphqlEndpoint, {
				method: "POST",
				body: request
			})
			.then(response => response.json())
			.then(json => {
				this.props.context.hideActivityIndicator();
				if (json.errors != null) {
					this.setState({
						message: json.errors[0].message,
						messageError: true,
						messageOpened: true,
					});
				}
				else {
					history.goBack();
				}
			});
		}
		else {
			let attributesStr = attributes
					.filter(attribute => attribute.incomingReferenceAttributeName === undefined
							&& !attribute.computed
							&& !(!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined))))
					.map(attribute => '$' 
							+ attribute.name 
							+ ': ' 
							+ this.getVariableType(attribute)
					)
					.join(" ");
			
			const query = 
				'mutation Create' + (attributesStr !== '' ? '(' + attributesStr + ')' : '') + '{ ' +
				'	result: ' + this.props.entity.replace(".", "_") + 'Create(' +
				'		entity: {' + 
				attributes
						.filter(attribute => attribute.incomingReferenceAttributeName === undefined
								&& !attribute.computed
								&& !(!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined))))
						.map(attribute => attribute.name + ': $' + attribute.name).join(" ") +
				'		}' +
				'	) {' +
				'		' + this.state.keyAttribute.name +
				'	}' +
				'}';
			
			//console.log("Query:");
			//console.log(query);
			
			const variables = {
				authorization: this.props.context.accessToken,
			};
			
			//console.log("InputRefs:");
			//console.log(this.inputRefs);
			
			attributes
					.filter(attribute => (attribute.incomingReferenceAttributeName === undefined
							&& !attribute.computed
							&& ((attribute.array && attribute.type === 'TEXT')
								|| entityLocalModel.attributes[attribute.name].type == "DOCUMENT"
								|| (this.inputRefs[attribute.name] != null 
									&& this.inputRefs[attribute.name].current !== null
									&& (this.inputRefs[attribute.name].current.type === 'checkbox' 
										|| this.inputRefs[attribute.name].current.value !== "")))))
					.forEach(attribute => variables[attribute.name] = this.getVariableValue(attribute));
			
			//console.log("Variables:");
			//console.log(JSON.stringify(variables));
			
			let request = JSON.stringify({query: query, variables: variables});
			fetch(this.props.context.graphqlEndpoint, {
				method: "POST",
				body: request
			})
			.then(response => response.json())
			.then(json => {
				this.props.context.hideActivityIndicator();
				if (json.errors != null) {
					this.setState({
						message: json.errors[0].message,
						messageError: true,
						messageOpened: true,
					});
				}
				else {
					history.replace("/admin/" + this.props.entity + '/' + json.data.result[this.state.keyAttribute.name] + "/edit");
				}
			});
		}
	}
	
	handleAddFile(error, file, attribute) {
		if (error === null) {
			let reader = new FileReader();
			let thisInstance = this;
			reader.onloadend = function() {
				if (attribute.array) {
					thisInstance.setState((state, props) => {
						if (state.uploadedData[attribute.name] == null) {
							state.uploadedData[attribute.name] = [];
						}
						state.uploadedData[attribute.name].push({
							name: file.file.name,
							size: file.file.size,
							type: file.file.type,
							data: reader.result,
						});
						return {
							uploadedData: state.uploadedData,
						};
					});
				}
				else {
					thisInstance.setState((state, props) => {
						state.uploadedData[attribute.name] = {
							name: file.file.name,
							size: file.file.size,
							type: file.file.type,
							data: reader.result,
						};
						return {
							uploadedData: state.uploadedData,
						};
					});
				}
			}
			reader.readAsDataURL(file.file);
		}
	}
	
	handleRemoveFile(file, attribute) {
		if (attribute.array) {
			this.setState((state, props) => {
				// TODO Ideally, we should filter by name, but we have a problem storing name and .....
				state.uploadedData[attribute.name] = state.uploadedData[attribute.name].filter(item => item.size !== file.fileSize);
				if (state.uploadedData[attribute.name] != null && state.uploadedData[attribute.name].length === 0) {
					state.uploadedData[attribute.name] = null;
				}
				return {
					uploadedData: state.uploadedData,
				};
			});
		}
		else {
			this.setState((state, props) => {
				state.uploadedData[attribute.name] = null;
				return {
					uploadedData: state.uploadedData,
				};
			});
		}
	}
	
	handleDropDocument(file, attribute) {
		let reader = new FileReader();
		let thisInstance = this;
		reader.onloadend = function() {
			thisInstance.setState((state, props) => {
				if (state.uploadedData[attribute.name] == null) {
					state.uploadedData[attribute.name] = [];
				}
				state.uploadedData[attribute.name].push({
					name: file.name,
					size: file.size,
					type: file.type,
					data: reader.result,
				});
				return {
					uploadedData: state.uploadedData,
				};
			});
		}
		reader.readAsDataURL(file);
	}
	
	handleDeleteDocument(file, attribute) {
		if (attribute.array) {
			this.setState((state, props) => {
				// TODO Ideally, we should filter by name, but we have a problem storing name and .....
				state.uploadedData[attribute.name] = state.uploadedData[attribute.name].filter(item => item.size !== file.fileSize);
				if (state.uploadedData[attribute.name] != null && state.uploadedData[attribute.name].length === 0) {
					state.uploadedData[attribute.name] = null;
				}
				return {
					uploadedData: state.uploadedData,
				};
			});
		}
	}
	
	// Life cycle methods
	
	componentDidMount() {
		// console.log(">> EntityView.componentDidMount");
		this._isMounted = true;
		this.refreshDataThrottled();
	}
	
	componentDidUpdate(prevProps) {
		// console.log(">> EntityView.componentDidUpdate");
		//window.scrollTo(0, 0);
		if (this.props.entity !== prevProps.entity 
				|| this.props.entityId !== prevProps.entityId
				|| this.props.mode !== prevProps.mode
				) {
			this.refreshDataThrottled();
		}
	}
	
	componentWillUnmount() {
		//console.log(">> EntityView.componentWillUnmount");
		this._isMounted = false;
	}
	
	// Other methods
	
	setQuotes(attribute, value) {
		const hasQuotes = (attribute.type === "TEXT"
			|| attribute.type === "DATE"
			|| attribute.type === "TIMESTAMP"
			|| attribute.type === "VARCHAR"
			|| attribute.type === "CHAR"
			|| attribute.type === "TIME"
			|| attribute.type === "INTERVAL"
			|| attribute.type === "TIMESTAMP_WITH_TIME_ZONE"
			|| attribute.type === "TIME_WITH_TIME_ZONE"
			|| attribute.type === "POINT"
			|| attribute.type === "POLYGON"
		);
		return (hasQuotes ? '"' : '') + value + (hasQuotes ? '"' : '');
	}
	
	getLabelAttributesQueryString(entityModel, entityLocalModel, exceptionAttribute, depth) {
		let str = "";

		if (depth == null) {
			depth = 1;
		}
		
		Object.values(entityModel.attributes)
			.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].label)
			.forEach(attribute => {
				if (exceptionAttribute == null || attribute.name != exceptionAttribute.name) {
					str += attribute.name + ", ";
				}
			});
		
		if (depth < 5) {
			Object.values(entityModel.references)
				.filter(reference => reference.referenceAttributeName != null && entityLocalModel.attributes[reference.referenceAttributeName].label)
				.forEach(reference => {
					const model = this.props.context.model;
					str += reference.referenceAttributeName + "{ " 
							+ this.getLabelAttributesQueryString(model.entities[reference.referencedKey.entityName], localModel.entities[reference.referencedKey.entityName], null, depth + 1)
							+ "}, ";
				});
		}
		
		return str;
	}
	
	getLabel(item, entityModel, entityLocalModel) {
		let label = "";
		
		if (item == null) {
			return null;
		}
		
		const model = this.props.context.model;

		let labels = Object.values(entityModel.attributes)
			.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].label)
			.map(attribute => {
				return {
					order: entityLocalModel.attributes[attribute.name].order,
					label: item[attribute.name],
				}
			});
		
		Object.values(entityModel.references)
			.filter(reference => reference.referenceAttributeName != null && entityLocalModel.attributes[reference.referenceAttributeName].label)
			.forEach(reference => {
				labels.push({
					order: entityLocalModel.attributes[reference.referenceAttributeName].order,
					label: this.getLabel(item[reference.referenceAttributeName], model.entities[reference.referencedKey.entityName], localModel.entities[reference.referencedKey.entityName])
				});
			});
		
		return labels.sort((a, b) => (a.order == null ? Infinity : a.order) - (b.order == null ? Infinity : b.order)).filter(label => label != null && label.label != null).map(label => label.label).join(", ");
	}
	
	setValues(attributes) {
		const model = this.props.context.model;
		const entityModel = model.entities[this.props.entity];
		const entityLocalModel = localModel.entities[this.props.entity];
		
		attributes
				.filter(attribute => attribute.incomingReferenceAttributeName === undefined)
				.forEach(attribute => {
			
			if (this.inputRefs[attribute.name] != null && this.inputRefs[attribute.name].current != null) {

				if (attribute.referenceAttributeName == null && entityLocalModel.attributes[attribute.name].type == "SIGNATURE") {
					if (this.state.data[attribute.name] != null) {
						this.inputRefs[attribute.name].current.fromData(JSON.parse(this.state.data[attribute.name]));
					}
					if (this.props.mode === "edit") {
						this.inputRefs[attribute.name].current.on();
					}
					else {
						this.inputRefs[attribute.name].current.off();
					}
				}
				else if (attribute.array && (attribute.type === "TEXT"
						|| attribute.type === "CHAR"
						|| attribute.type === "VARCHAR") && attribute.enumType === undefined) {
					// No tengo que asignar nada porque el componente ChipInput se utiliza en modo "controlled"
				}
				else if (!attribute.array 
						&& attribute.type === "BOOLEAN") {
					// No tengo que asignar nada porque el componente Checkbox se utiliza en modo "controlled"
				}
				else if (!attribute.array
						&& attribute.referenceAttributeName !== undefined) {
					let subKeyAttribute = Object.values(model.entities[attribute.referencedKey.entityName].keys).filter(key => key.primaryKey)[0].attributes[0];
					let selectedValue = (this.state.data == null || this.state.data[attribute.referenceAttributeName] == null ? null : {
						value: this.state.data[attribute.referenceAttributeName][subKeyAttribute.name], 
						label: this.state.data[attribute.referenceAttributeName] === null ? "" : this.getLabel(this.state.data[attribute.referenceAttributeName], model.entities[attribute.referencedKey.entityName], localModel.entities[attribute.referencedKey.entityName])
					});
					this.inputRefs[attribute.name].current.select.select.setValue(selectedValue, 'select-option');
				}
				else if (attribute.array
						&& attribute.enumType != null) {
					Object.values(this.inputRefs[attribute.name].current.options).forEach(option => option.selected = this.state.data[attribute.name] != null && this.state.data[attribute.name].filter(item => item == option.value).length > 0);
				}
				else {
					this.inputRefs[attribute.name].current.value = (this.state.data[attribute.name] == null ? "" : this.state.data[attribute.name]);
				}
			}
		});
	}
	
	refreshData() {
		//console.log(">> EntityView.refreshData")
		this.props.context.showActivityIndicator();
		
		const model = this.props.context.model;
		const entityModel = model.entities[this.props.entity];
		const entityLocalModel = localModel.entities[this.props.entity];
		
		//console.log(entityModel);
		
		let attributes = Object.values(entityModel.attributes)
				.filter(attribute => model.super || attribute.privileges["SELECT"] !== undefined)
				.map(attribute => {
					if (entityLocalModel.attributes[attribute.name] != null) {
						attribute["multiline"] = entityLocalModel.attributes[attribute.name].multiline;
						attribute["rich"] = entityLocalModel.attributes[attribute.name].rich;
						attribute["xs"] = entityLocalModel.attributes[attribute.name].xs;
						attribute["sm"] = entityLocalModel.attributes[attribute.name].sm;
						attribute["min"] = entityLocalModel.attributes[attribute.name].min;
						attribute["max"] = entityLocalModel.attributes[attribute.name].max;
						attribute["step"] = entityLocalModel.attributes[attribute.name].step;
						attribute["length"] = entityLocalModel.attributes[attribute.name].length;
						attribute["pattern"] = entityLocalModel.attributes[attribute.name].pattern;
						attribute["password"] = entityLocalModel.attributes[attribute.name].password;
						attribute["maxFiles"] = entityLocalModel.attributes[attribute.name].maxFiles;
						attribute["computed"] = entityLocalModel.attributes[attribute.name].computed;
						attribute["acceptedFileTypes"] = entityLocalModel.attributes[attribute.name].acceptedFileTypes;
					}
					return attribute;
				});
		
		Object.values(entityModel.references)
				.filter(reference => { 
					return entityLocalModel.attributes[reference.referenceAttributeName] === undefined
							|| entityLocalModel.attributes[reference.referenceAttributeName].visible === undefined
							|| entityLocalModel.attributes[reference.referenceAttributeName].visible;
				})
				.forEach(reference => {
					attributes.push({
						name: reference.name,
						entityName: reference.entityName,
						referenceAttributeName: reference.referenceAttributeName,
						attributes: reference.attributes,
						referencedKey: reference.referencedKey,
						
						privileges: entityModel.attributes[reference.name].privileges,
						type: entityModel.attributes[reference.name].type,
						required: entityModel.attributes[reference.name].required,
						array: entityModel.attributes[reference.name].array,
					});
				});
		
		Object.values(model.entities)
				.forEach(entity => Object.values(entity.references)
						.filter(reference => reference.referencedKey.entityName === this.props.entity)
						.forEach(reference => {
							attributes.push({
								name: reference.name,
								entityName: reference.entityName,
								incomingReferenceAttributeName: reference.incomingReferenceAttributeName,
								attributes: reference.attributes,
								referencedKey: reference.referencedKey,
							});
						}
				)
		);
		
		attributes.sort((a, b) => 
			(	
				entityLocalModel.attributes[a.incomingReferenceAttributeName || a.referenceAttributeName || a.name] === undefined 
					|| entityLocalModel.attributes[a.incomingReferenceAttributeName || a.referenceAttributeName || a.name].order == null 
						? Infinity 
						: entityLocalModel.attributes[a.incomingReferenceAttributeName || a.referenceAttributeName || a.name].order
			) - (
				entityLocalModel.attributes[b.incomingReferenceAttributeName || b.referenceAttributeName || b.name] === undefined 
					|| entityLocalModel.attributes[b.incomingReferenceAttributeName || b.referenceAttributeName || b.name].order == null 
						? Infinity 
						: entityLocalModel.attributes[b.incomingReferenceAttributeName || b.referenceAttributeName || b.name].order
			)
		);
		
		attributes = attributes
				.filter(attribute => 
						!entityLocalModel.attributes[
								attribute.incomingReferenceAttributeName 
								|| attribute.referenceAttributeName 
								|| attribute.name
						]
						|| entityLocalModel.attributes[
								attribute.incomingReferenceAttributeName 
								|| attribute.referenceAttributeName 
								|| attribute.name
						].visible === undefined
						|| entityLocalModel.attributes[
								attribute.incomingReferenceAttributeName 
								|| attribute.referenceAttributeName 
								|| attribute.name
						].visible
				);
		
		//console.log("Attributes:");
		//console.log(attributes);
		
		if (attributes != null && attributes.length > 0) {
			for (let i = 0; i < attributes.length; i++) {
				if (this.props.preselectedAttribute == null || attributes[i].name != this.props.preselectedAttribute) {
					attributes[i]["_autoFocus"] = true;
					break;
				}
			}
		}
		
		if (this.inputRefs == null) {
			this.inputRefs = {};
		}
		attributes
				.filter(attribute => attribute.incomingReferenceAttributeName === undefined)
				.forEach(attribute => {
			if (this.inputRefs[attribute.name] == null) {
				this.inputRefs[attribute.name] = React.createRef();
			}
		});
		
		//console.log("InputRefs:");
		//console.log(this.inputRefs);
		
		let selectedTab = null;
		if (entityLocalModel.tabs != null) {
			let selectedTabItem = Object.values(entityLocalModel.tabs).filter(tab => Object.values(entityLocalModel.attributes).filter(attribute => attribute.tab === tab.id).length > 0)[0];
			if (selectedTabItem != null) {
				selectedTab = selectedTabItem.id;
			}
		}
		
		let keyAttribute = Object.values(entityModel.keys).filter(key => key.primaryKey)[0].attributes[0];
		/*
		console.log(">>>");
		console.log(selectedTabMap);
		*/
		
		if (this.props.entityId !== undefined) {
			const query = 
					'{ ' +
					'	result:' + this.props.entity.replace(".", "_") + 'List(' +
					'		limit: 1' +
					'       where: {' + keyAttribute.name + ': {EQ: ' + this.setQuotes(keyAttribute, this.props.entityId) + '}}' +
					'	) {' + keyAttribute.name + ' ' +
					'   	' + attributes.map(attribute => {

							if (attribute.referenceAttributeName !== undefined) {
								let subKeyAttribute = Object.values(model.entities[attribute.referencedKey.entityName].keys).filter(key => key.primaryKey)[0].attributes[0];
								return attribute.referenceAttributeName + "{ " + subKeyAttribute.name + ", " + this.getLabelAttributesQueryString(model.entities[attribute.referencedKey.entityName], localModel.entities[attribute.referencedKey.entityName], subKeyAttribute) + "}";
							}
							else if (attribute.incomingReferenceAttributeName === undefined
									&& attribute.customType == null) {
								return attribute.name;
							}
							else if (attribute.customType != null
									&& attribute.customType.schema == 'Models'
									&& attribute.customType.name == 'documentType') {
								return attribute.name + "{ name size type search data }";
							}
							return null;
						}).join(" ") + 
					'	} ' +
					'}';
			
			//console.log("Query:");
			//console.log(query);
			
			const variables = {
	    		authorization: this.props.context.accessToken
	    	};
			let request = JSON.stringify({query: query, variables: variables});
			fetch(this.props.context.graphqlEndpoint, {
				method: "POST",
				body: request
			})
			.then(response => response.json())
			.then(json => {
				if (this._isMounted) {
					//console.log("Data result:");
					//console.log(json);
					
					let state = {
							keyAttribute: keyAttribute,
							currentEntity: this.props.entity,
							data: json.data["result"][0],
							inputRefs: this.inputRefs,
							attributes: attributes,
							selectedTab: selectedTab,
							uploadedData: {},
						};

					attributes
							.filter(attribute => attribute.incomingReferenceAttributeName === undefined
									&& attribute.referenceAttributeName === undefined
									&& entityLocalModel.attributes[attribute.name].type == "DOCUMENT")
							.forEach(attribute => {
								state.uploadedData[attribute.name] = [];
								if (attribute.array) {
									if (state.data[attribute.name] != null) {
										state.uploadedData[attribute.name] = state.data[attribute.name];
									}
								}
								else {
									if (state.data[attribute.name] != null) {
										state.uploadedData[attribute.name].push(state.data[attribute.name]);
									}
								}
							});
					
					this.setState(state, () => {
						this.setValues(attributes);
						this.props.context.hideActivityIndicator();
					});
				}
			})
			.catch(error => {
				console.log("!!!!! Retrying...");
				console.log(error);
				setTimeout(this.refreshDataThrottled(), 500);
			});
		}
		else {
			let data = {};
			
			attributes.filter(attribute => !attribute.array && attribute.type === 'BOOLEAN')
					.forEach(attribute => data[attribute.name] = false);
			
			if (this.props.preselectedAttribute != null) {
				let attribute = attributes.filter(attribute => attribute.referenceAttributeName != null && attribute.name == this.props.preselectedAttribute)[0];
				let subKeyAttribute = Object.values(model.entities[attribute.referencedKey.entityName].keys).filter(key => key.primaryKey)[0].attributes[0];
				
				const query = 
					'{ ' +
					'	result:' + attribute.referencedKey.entityName.replace(".", "_") + 'List(' +
					'		limit: 1' +
					'       where: {' + subKeyAttribute.name + ': {EQ: ' + this.setQuotes(subKeyAttribute, this.props.preselectedValue) + '}}' +
					'	) {' + subKeyAttribute.name + ', ' + this.getLabelAttributesQueryString(model.entities[attribute.referencedKey.entityName], localModel.entities[attribute.referencedKey.entityName], subKeyAttribute) +
					'	} ' +
					'}';
				
				//console.log(query);
				
				const variables = {
		    		authorization: this.props.context.accessToken
		    	};
				let request = JSON.stringify({query: query, variables: variables});
				fetch(this.props.context.graphqlEndpoint, {
					method: "POST",
					body: request
				})
				.then(response => response.json())
				.then(json => {
					if (this._isMounted) {
						
						data[attribute.referenceAttributeName] = json.data["result"][0];
						
						this.setState({
							keyAttribute: keyAttribute,
							currentEntity: this.props.entity,
							data: data,
							inputRefs: this.inputRefs,
							attributes: attributes,
							selectedTab: selectedTab,
						}, () => {
							if (this.props.preselectedAttribute != null) {
								let attribute = attributes.filter(attribute => attribute.name === this.props.preselectedAttribute && attribute.referenceAttributeName != null)[0];
								if (this.inputRefs[this.props.preselectedAttribute] != null && this.inputRefs[this.props.preselectedAttribute].current != null) {
									let subKeyAttribute = Object.values(model.entities[attribute.referencedKey.entityName].keys).filter(key => key.primaryKey)[0].attributes[0];
									let selectedValue = (this.state.data == null || this.state.data[attribute.referenceAttributeName] == null ? null : {
										value: this.state.data[attribute.referenceAttributeName][subKeyAttribute.name], 
										label: this.state.data[attribute.referenceAttributeName] === null ? "" : this.getLabel(this.state.data[attribute.referenceAttributeName], model.entities[attribute.referencedKey.entityName], localModel.entities[attribute.referencedKey.entityName])
									});
									
									//console.log(selectedValue);
									this.inputRefs[attribute.name].current.select.select.setValue(selectedValue, 'select-option');
								}
							}
							this.setValues(attributes);
							this.props.context.hideActivityIndicator()
						});
					}
				})
				.catch(error => {
					console.log("!!!!! Retrying...");
					console.log(error);
					setTimeout(this.refreshDataThrottled(), 500);
				});
			}
			else {
				this.setState({
					keyAttribute: keyAttribute,
					currentEntity: this.props.entity,
					data: data,
					inputRefs: this.inputRefs,
					attributes: attributes,
					selectedTab: selectedTab,
				}, () => {
					this.setValues(attributes);
					this.props.context.hideActivityIndicator()
				});
			}
		}
	}
	
	renderGroup(hasTab, group) {
		//console.log(">> EntityView.render");
		
		const { classes, t } = this.props;
		const inputRefs = this.inputRefs;
		const { selectedTab } = this.state;
		
		const model = this.props.context.model;
		
		const entityModel = model.entities[this.props.entity];
		const entityLocalModel = localModel.entities[this.props.entity];
		var attributes = Object.values(this.state.attributes);

		for (var i = attributes.length - 1; i >= 0; i--) {
			if (entityLocalModel.attributes[attributes[i].name] && entityLocalModel.attributes[attributes[i].name].lastInRow) {
				attributes.splice(i + 1, 0, {emptyRow: true, name: (attributes[i].incomingReferenceAttributeName != null ? attributes[i].incomingReferenceAttributeName : attributes[i].name) + "Break"});
			}
		}
		
		return attributes
				.map(attribute => 
			
			(attribute.emptyRow
					&&
				<Grid key={attribute.name} style={{padding: 0}} item sm={12}/>
			)
			||
			
			// Todos los campos que no son referencias
			(attribute.incomingReferenceAttributeName === undefined 
					&& attribute.referenceAttributeName === undefined
					&& entityLocalModel.attributes[attribute.name] != null
					&& (entityLocalModel.attributes[attribute.name].tab != null) === hasTab
					&& entityLocalModel.attributes[attribute.name].group == group
					&& 
				(
					// Campos de tipo firma manuscrita con un valor
					(
						entityLocalModel.attributes[attribute.name].type === 'SIGNATURE'
								&& 
							<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
								className={!hasTab || entityLocalModel.attributes[attribute.name].tab === selectedTab ? '' : classes.hidden}
							>
								<InputLabel className={classes.inputLabel} shrink required>{t('e.' + this.props.entity + '.a.' + attribute.name)}</InputLabel>
								<div className={classes.dashedBorder}>
									{
										this.props.mode === "edit"
											&&
										<Tooltip title={t('clear')} className={classes.alignButton}>
											<IconButton
													aria-label={t('clear')}
													onClick={event => this.inputRefs[attribute.name].current.clear()}>
												<ClearIcon/>
											</IconButton>
										</Tooltip>
									}
									<SignaturePad 
											className={classes.signaturePad}
											redrawOnResize
											ref={inputRefs[attribute.name]}
											options={{
												redrawOnResize: true,
											}}
									/>
								</div>
							</Grid>
					) 
					|| 
					
					// Campos de tipo documento con uno o varios valores
					(
						entityLocalModel.attributes[attribute.name].type === 'DOCUMENT'
								&& 
							<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
								data-qa={attribute.name + "-input"} 
								className={!hasTab || entityLocalModel.attributes[attribute.name].tab === selectedTab ? '' : classes.hidden}
							>
								<InputLabel className={classes.inputLabel} shrink required={attribute.required}>{t('e.' + this.props.entity + '.a.' + attribute.name)}</InputLabel>
								<br/>
								<DropzoneArea 
										disabled={attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
										onDrop={file => this.handleDropDocument(file, attribute)}
										onDelete={file => this.handleDeleteDocument(file, attribute)}
										fileObjects={this.state.uploadedData[attribute.name]}
										acceptedFiles={(attribute.acceptedFileTypes == null ? [] : attribute.acceptedFileTypes)}
										filesLimit={(attribute.array ? (attribute.maxFiles == null ? 5 : attribute.maxFiles) : 1)}
								/>
							</Grid>
					) 
					|| 
					
					// Campos de texto sencillos con un único valor
					(
						!attribute.array
								&& !attribute.rich
								&& !attribute.password
								&& (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR") 
								&& attribute.enumType === undefined 
								&& 
							<Grid key={attribute.name} 
									item 
									xs={attribute.xs || 12} 
									sm={attribute.sm || (attribute.multiline ? 12 : 6)} 
									className={!hasTab || entityLocalModel.attributes[attribute.name].tab === selectedTab ? '' : classes.hidden}
							>
								<TextField
										multiline={attribute.multiline}
										required={attribute.required}
										inputRef={inputRefs[attribute.name]}
										label={t('e.' + this.props.entity + '.a.' + attribute.name)} 
										rows={3}
										rowsMax={10}
										fullWidth
										inputProps={{
											"data-qa": attribute.name + "-input",
											maxLength: attribute.length, 
											pattern: attribute.pattern, 
											title: attribute.pattern
										}}
										InputLabelProps={{shrink: true}}
										autoFocus={attribute._autoFocus}
										disabled={attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
								/>
							</Grid>
					)
					||
					
					(
						!attribute.array
								&& !attribute.rich
								&& attribute.password
								&& (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR") 
								&& attribute.enumType === undefined 
								&& 
							<Grid key={attribute.name} 
									item 
									xs={attribute.xs || 12} 
									sm={attribute.sm || (attribute.multiline ? 12 : 6)} 
									className={!hasTab || entityLocalModel.attributes[attribute.name].tab === selectedTab ? '' : classes.hidden}
							>
								<FormControl fullWidth>
									<InputLabel	shrink>{t('e.' + this.props.entity + '.a.' + attribute.name)}</InputLabel>
									<br/>
									<PasswordField
											required={attribute.required} 
											inputRef={inputRefs[attribute.name]}
											fullWidth
											style={{height: "29px"}}
											autoComplete="off"
											inputProps={{
												"data-qa": attribute.name + "-input",
												maxLength: attribute.length, 
												pattern: attribute.pattern, 
												title: attribute.pattern}}
											autoFocus={attribute._autoFocus}
											disabled={attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
									/>
								</FormControl>
							</Grid>
					)
					||
					
					// Campos de tipo imagen con una o varias imágenes
					(
						attribute.type === "BYTEA" 
								&&
							<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
									className={!hasTab || entityLocalModel.attributes[attribute.name].tab === selectedTab ? '' : classes.hidden}
							>
								<FormControl 
										required={attribute.required} 
										disabled={attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
										fullWidth>
									<InputLabel	shrink>{t('e.' + this.props.entity + '.a.' + attribute.name)}</InputLabel>
									<br/>
									<FilePond 
											onaddfile={(error, file) => this.handleAddFile(error, file, attribute)}
											onremovefile={file => this.handleRemoveFile(file, attribute)}
											beforeRemoveFile={(item) => this.props.mode !== 'view' && (model.super || attribute.privileges["UPDATE"] !== undefined)}
											ref={inputRefs[attribute.name]}
											required={attribute.required}
											allowDrop={this.props.mode !== 'view' && (model.super || attribute.privileges["UPDATE"] !== undefined)}
											allowBrowse={this.props.mode !== 'view' && (model.super || attribute.privileges["UPDATE"] !== undefined)}
											allowMultiple={attribute.array}
											allowPaste={false}
											maxFiles={attribute.maxFiles || 5}
											allowImagePreview
											imagePreviewMaxHeight="440"
											allowFilePoster
											allowFileTypeValidation
											acceptedFileTypes={attribute.acceptedFileTypes || ['image/*']}
											labelIdle={t('uploadLabel')}
											instantUpload={false}
									>
										{
											(
												!attribute.array && this.state.data[attribute.name] != null 
														&& 
													<File src={JSON.parse(this.state.data[attribute.name]).data} origin="local"/>
											)
											|| 
											(
												attribute.array 
														&& this.state.data[attribute.name] != null 
														&& this.state.data[attribute.name].map((item, index) => 
													<File key={index} src={JSON.parse(item).data} origin="local"/>
												)
											)
										}
									</FilePond>
								</FormControl>
							</Grid>
					) 
					||
					
					// Campos de texto enriquecido con un único valor
					(
						!attribute.array
								&& attribute.rich
								&& (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR") 
								&& attribute.enumType === undefined 
								&&
							<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 12}
									className={!hasTab || entityLocalModel.attributes[attribute.name].tab === selectedTab ? '' : classes.hidden}
							>
								<FormControl 
										required={attribute.required} 
										disabled={attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
										fullWidth>
									<InputLabel	shrink>{t('e.' + this.props.entity + '.a.' + attribute.name)}</InputLabel>
									<ReactQuill className={classes.quill}/>
								</FormControl>
							</Grid>
					) 
					||	
					
					// Campos enumerados con un único valor o con múltiples valores
					(
						(attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR") 
								&& attribute.enumType !== undefined 
								&& 
							<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
								className={!hasTab || entityLocalModel.attributes[attribute.name].tab === selectedTab ? '' : classes.hidden}
							>
								<FormControl 
										required={attribute.required} 
										disabled={attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
										fullWidth>
									<InputLabel	shrink>{t('e.' + this.props.entity + '.a.' + attribute.name)}</InputLabel>
									<Select 
											inputRef={inputRefs[attribute.name]}
											native
											multiple={attribute.array}
											autoFocus={attribute._autoFocus}
											inputProps={{
												"data-qa": attribute.name + "-input"
											}}>
										>
										{
											!attribute.array
												&&
											<option value=""></option>
										}
										{ 
											Object.values(attribute.enumType.values).map(value => (
												<option key={value} value={value}>{t('enums.' + attribute.enumType.schema + '.' + attribute.enumType.name + '.v.' + value)}</option>
											))
										}
									</Select>
								</FormControl>
							</Grid>
					)
					||
					
					// Campos booleanos con un único valor
					(
						!attribute.array 
								&& attribute.type === "BOOLEAN" 
								&& 
							<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
								className={!hasTab || entityLocalModel.attributes[attribute.name].tab === selectedTab ? '' : classes.hidden}
							>
								<FormControlLabel
										control={
												<Checkbox color="secondary" 
														onChange={(event, checked) => this.handleCheckboxChange(event, checked, attribute)} 
														checked={this.state.data[attribute.name]}
														inputProps={{
															"data-qa": attribute.name + "-input"
														}}
												/>
										}
										inputRef={inputRefs[attribute.name]}
										disabled={attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
										label={t('e.' + this.props.entity + '.a.' + attribute.name)}/>
							</Grid>
					)
					||
					
					// Campos de tipo entero con un único valor
					// Campos de tipo numérico con decimales con un único valor
					(
						!attribute.array 
								&& (attribute.type === "INTEGER"
										|| attribute.type === "SMALLINT"
										|| attribute.type === "BIGINT"
										|| attribute.type === "SERIAL"
										|| attribute.type === "DECIMAL"
										|| attribute.type === "DOUBLE_PRECISION"
										|| attribute.type === "REAL"
										|| attribute.type === "MONEY"
										|| attribute.type === "SMALLSERIAL"
										|| attribute.type === "BIGSERIAL") 
								&& 
							<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
								className={!hasTab || entityLocalModel.attributes[attribute.name].tab === selectedTab ? '' : classes.hidden}
							>
								<TextField 
										required={attribute.required}
										inputRef={inputRefs[attribute.name]}
										label={t('e.' + this.props.entity + '.a.' + attribute.name)} 
										fullWidth
										inputProps={{
											"data-qa": attribute.name + "-input",
											min: attribute.min, 
											max: attribute.max, 
											step: attribute.step
										}}
										InputLabelProps={{shrink: true}}
										autoFocus={attribute._autoFocus}
										disabled={attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
										type="number"
								/>
							</Grid>
					) 
					||
					
					// Campos de tipo fecha con un único valor
					(
						!attribute.array 
								&& attribute.type === "DATE" 
								&& 
							<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
								className={!hasTab || entityLocalModel.attributes[attribute.name].tab === selectedTab ? '' : classes.hidden}
							>
								<TextField 
										required={attribute.required} 
										inputRef={inputRefs[attribute.name]}
										label={t('e.' + this.props.entity + '.a.' + attribute.name)} 
										fullWidth
										inputProps={{
											"data-qa": attribute.name + "-input",
										}}
										InputLabelProps={{shrink: true}}
										autoFocus={attribute._autoFocus}
										disabled={attribute.computed || attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
										type="date"
								/>
							</Grid>
					) 
					|| 
					
					// Campos de tipo timestamp con un único valor
					(
						!attribute.array 
								&& (attribute.type === "TIMESTAMP" || attribute.type === "TIMESTAMP_WITH_TIME_ZONE") 
								&& 
							<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
								className={!hasTab || entityLocalModel.attributes[attribute.name].tab === selectedTab ? '' : classes.hidden}
							>
								<TextField 
										required={attribute.required} 
										inputRef={inputRefs[attribute.name]}
										label={t('e.' + this.props.entity + '.a.' + attribute.name)} 
										fullWidth
										inputProps={{
											"data-qa": attribute.name + "-input",
										}}
										InputLabelProps={{shrink: true}}
										autoFocus={attribute._autoFocus}
										disabled={attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
										type="datetime-local"
										//defaultValue={(this.state.data == null || this.state.data[attribute.name] == null ? "" : this.state.data[attribute.name]).split(".")[0]}
								/>
							</Grid>
					) 
					||
					
					// Campos de tipo hora con un único valor
					(
						!attribute.array 
								&& (attribute.type === "TIME" || attribute.type === "TIME_WITH_TIME_ZONE") 
								&& 
							<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
								className={!hasTab || entityLocalModel.attributes[attribute.name].tab === selectedTab ? '' : classes.hidden}
							>
								<TextField 
										required={attribute.required} 
										inputRef={inputRefs[attribute.name]}
										label={t('e.' + this.props.entity + '.a.' + attribute.name)} 
										fullWidth
										inputProps={{
											"data-qa": attribute.name + "-input",
										}}
										InputLabelProps={{shrink: true}}
										autoFocus={attribute._autoFocus}
										disabled={attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
										type="time"
								/>
							</Grid>
					) 
					||
					
					// Campos de tipo texto con varios valores
					(
						attribute.array 
								&& (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR") 
								&& attribute.enumType === undefined 
								&& 
							<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
								className={!hasTab || entityLocalModel.attributes[attribute.name].tab === selectedTab ? '' : classes.hidden}
							>
								<ChipInput
										label={t('e.' + this.props.entity + '.a.' + attribute.name)} 
										fullWidth
										InputProps={{
											inputProps: {
												maxLength: attribute.length, 
												"data-qa": attribute.name + "-input",
											},
										}}
										InputLabelProps={{
											shrink: true,
										}}
										value={(this.state.data == null || this.state.data[attribute.name] == null ? [] : this.state.data[attribute.name])}
										onAdd={(chip) => this.handleAddChip(chip, attribute)}
										onDelete={(chip, index) => this.handleDeleteChip(chip, index, attribute)}
										autoFocus={attribute._autoFocus}
										disabled={attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
								/>
							</Grid>
					) 
					|| 
					
					// Campos no soportados
					(
						<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
							className={!hasTab || entityLocalModel.attributes[attribute.name].tab === selectedTab ? '' : classes.hidden}
						>
							<Typography variant="h6" className={classes.title} color="inherit" noWrap>NOT_SUPPORTED_YET</Typography>
							<span>{attribute.name + ", " + attribute.type + ", " + (this.state.data == null ? "" : this.state.data[attribute.name])}</span>
						</Grid>
					)
				)
			)
			
			// Referencias directas
			|| (
				this.state.data != null
						&& attribute.referenceAttributeName !== undefined 
						&& entityLocalModel.attributes[attribute.referenceAttributeName] != null 
						&& (entityLocalModel.attributes[attribute.referenceAttributeName].tab != null) === hasTab
						&& entityLocalModel.attributes[attribute.referenceAttributeName].group == group
						&&
			
					// Campos de tipo referencia directa con un único valor
					((
						!attribute.array
								&&
							<Grid key={attribute.referenceAttributeName} data-qa={attribute.referenceAttributeName + "-input"} item xs={entityLocalModel.attributes[attribute.referenceAttributeName].xs || 12} sm={entityLocalModel.attributes[attribute.referenceAttributeName].sm || 6}
								className={!hasTab || entityLocalModel.attributes[attribute.referenceAttributeName].tab === selectedTab ? '' : classes.hidden}
							>
								<AutoComplete
										required={attribute.required} 
										entityName={Object.values(entityModel.references).filter(reference => reference.name === attribute.name)[0].referencedKey.entityName}
										asyncRef={inputRefs[attribute.name]}
										autoFocus={attribute._autoFocus}
										disabled={attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
										label={t('e.' + this.props.entity + '.a.' + attribute.name)}
								/>
							</Grid>
					)			
					||
					
					// Campos de tipo referencia directa con múltiples valores
					(
						attribute.array
								&&
							<Grid key={attribute.referenceAttributeName} item xs={entityLocalModel.attributes[attribute.referenceAttributeName].xs || 12} sm={entityLocalModel.attributes[attribute.referenceAttributeName].sm || 6}
								className={!hasTab || entityLocalModel.attributes[attribute.referenceAttributeName].tab === selectedTab ? '' : classes.hidden}
							>
								<AutoComplete
										required={attribute.required} 
										entityName={Object.values(entityModel.references).filter(reference => reference.name === attribute.name)[0].referencedKey.entityName}
										asyncRef={inputRefs[attribute.name]}
										autoFocus={attribute._autoFocus}
										disabled={attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
										label={t('e.' + this.props.entity + '.a.' + attribute.name)}
								/>
							</Grid>
					))			
			)
			
			// Referencias inversas
			|| (
				this.props.entityId !== undefined 
						&& this.state.data != null
						&& attribute.incomingReferenceAttributeName !== undefined 
						&& entityLocalModel.attributes[attribute.incomingReferenceAttributeName] != null 
						&& (entityLocalModel.attributes[attribute.incomingReferenceAttributeName].tab != null) === hasTab
						&& entityLocalModel.attributes[attribute.incomingReferenceAttributeName].group == group
						&& 
					<Grid key={attribute.incomingReferenceAttributeName} item xs={attribute.xs || 12} sm={attribute.sm || 12}
						className={!hasTab || entityLocalModel.attributes[attribute.incomingReferenceAttributeName].tab === selectedTab ? '' : classes.hidden}
					>
						<EntityList mode={this.props.mode} entity={attribute.entityName} filterAttribute={attribute.name} filterValue={this.props.entityId}/>
					</Grid>
			)
		);
	}
	
	// Render
	render() {
		//console.log(">> EntityView.render");
		
		if (this.state != null 
				&& this.props.context.model !== null
				&& this.state.currentEntity === this.props.entity) {
			
			const { classes, t } = this.props;
			const { selectedTab } = this.state;
			
			const model = this.props.context.model;
			
			const entityModel = model.entities[this.props.entity];
			const entityLocalModel = localModel.entities[this.props.entity];
			
			//console.log(entityModel);
			//console.log(entityLocalModel);
			
			return (
				<div onKeyDown={this.handleKeyDown}>
					<form onSubmit={this.handleSaveClick}>
						<Paper square>
							<Toolbar className={classes.toolbar}>
								<Typography variant="h6" className={classes.title} color="inherit" noWrap>{t('e.' + this.props.entity + '.pluralName') + (this.state.data == null ? " (" + t('newRecord') + ")" : " (" + this.getLabel(this.state.data, entityModel, entityLocalModel) + ")")}</Typography>
								<div className={classes.spacer}/>
								{
									(
										this.props.mode === 'view' 
												&& (model.super	|| model.entities[this.props.entity].privileges["UPDATE"] !== undefined) 
												&&
											<Tooltip title={t('edit')}>
												<IconButton autoFocus
														aria-label={t('edit')}
														onClick={this.handleEditClick}>
													<EditIcon/>
												</IconButton>
											</Tooltip>
									) || (
										this.props.mode === 'edit' 
												&& 
											<Tooltip title={t('save')}>
												<IconButton 
														data-qa={(this.props.entityId !== undefined ? "edit-save-button" : "new-save-button")}
														type="submit"
														aria-label={t('save')}>
													<SaveIcon/>
												</IconButton>
											</Tooltip>
									)
								}
								<Tooltip title={t('cancel')}>
									<IconButton autoFocus
											data-qa={this.props.entity + (this.props.mode === 'edit' ? (this.props.entityId !== undefined ? "-edit" : "-new") : '-view') + "-cancel-button"}
											aria-label={t('cancel')}
											onClick={this.handleCancelClick}>
										<CancelIcon/>
									</IconButton>
								</Tooltip>
							</Toolbar>
						</Paper>
						<Paper square className={classes.paper}>
							<Grid container spacing={24}>
								{
									// Elements with !tab && !group
									this.renderGroup(false, null)
								}
								{
									// Elements with !tab && group
									entityLocalModel.groups != null
											&& Object.values(entityLocalModel.groups).map(group => 
													Object.values(entityLocalModel.attributes)
															.filter(attribute => attribute.group === group.id && attribute.tab == null)
															.length > 0
											&&
										<>
											<Grid key={group.id} item xs={12}>
												<Typography variant="subtitle2" 
														color="inherit" 
														noWrap>
													{t('e.' + this.props.entity + '.groups.' + group.id)}
												</Typography>
											</Grid>
											{
												this.renderGroup(false, group.id)
											}
										</>
									)
								}
								{
									// Tabs
									entityLocalModel.tabs != null
											&& Object.values(entityLocalModel.attributes).filter(attribute => attribute.tab != null).length > 0
											&&
									<Grid item xs={12}>
										<Tabs classes={{root: classes.tabsRoot}} value={selectedTab} onChange={this.handleChangeSelectedTab}>
											{
												Object.values(entityLocalModel.tabs).map(tab => 
														Object.values(entityLocalModel.attributes).filter(attribute => attribute.tab === tab.id).length > 0
															&&
													<Tab value={tab.id} key={tab.id} classes={{root: classes.tabRoot}} label={t('e.' + this.props.entity + '.tabs.' + tab.id)}/>
												)
											}
										</Tabs>
									</Grid>
								}
								{
									// Elements with tab && !group
									this.renderGroup(true, null)
								}
								{
									// Elements with tab && group
									entityLocalModel.groups != null
											&& entityLocalModel.tabs != null
											&& Object.values(entityLocalModel.tabs).map(tab =>
												Object.values(entityLocalModel.groups).map(group => 
													Object.values(entityLocalModel.attributes)
															.filter(attribute => attribute.group === group.id && attribute.tab == tab.id)
															.length > 0
											&&
										<>
											<Grid key={group.id} 
													item xs={12}
													className={tab.id === selectedTab ? '' : classes.hidden}
											>
												<Typography variant="subtitle2" 
														className={classes.formGroup} 
														color="inherit" 
														noWrap>
													{t('e.' + this.props.entity + '.groups.' + group.id)}
												</Typography>
											</Grid>
											{
												this.renderGroup(true, group.id)
											}
										</>
									))
								}
								<br/>
							</Grid>
						</Paper>
					</form>
					<Snackbar
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							autoHideDuration={5000}
							onClose={event => this.setState({ messageOpened: false })}
							open={this.state && this.state.messageOpened}>
						<SnackbarContent
								className={this.state.messageError ? classes.snackbarError : classes.snackbar}
								message={<><span className={classes.message}>{this.state.messageError && <ErrorIcon className={classes.icon}/>}{this.state.message}</span></>}
						/>
					</Snackbar>
				</div>
			);
		}
		else {
			return null;
		}
	}
}

EntityView.propTypes = {
	context: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	entity: PropTypes.string.isRequired,
	entityId: PropTypes.string,
	mode: PropTypes.string.isRequired,
	preselectedAttribute: PropTypes.string,
	preselectedValue: PropTypes.string,
};

export default withStyles(styles)(withContext(withI18n()(EntityView)));

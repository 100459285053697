import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { AppContext, withContext } from './App';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import { Redirect } from 'react-router-dom';
import { withI18n } from 'react-i18next';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import MenuItem from '@material-ui/core/MenuItem';
import i18n from 'i18next';
import { localModel } from './localModel';
import { resources } from './i18n';

const styles = theme => ({
	
	layout: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	
	paper: {
		marginTop: theme.spacing.unit * 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
	},
	
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main,
	},
	
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	
	submit: {
		marginTop: theme.spacing.unit * 3,
	},
	
	icon: {
		fontSize: 20,
		marginRight: theme.spacing.unit,
	},
	
	snackbar: {
		backgroundColor: theme.palette.error.dark,
	},
	
	message: {
		display: 'flex',
		alignItems: 'center',
	},
});

class SignIn extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			snackbarClosed: true,
			errorMessage: null,	
			username: "",
			password: "",
			language: i18n.language,
		};
		
		this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
		this.handleLogin = this.handleLogin.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleLanguageChange = this.handleLanguageChange.bind(this);
	}

	handleLanguageChange(event) {
		i18n.changeLanguage(event.target.value);
		this.setState({
			language: event.target.value
		});
	}
	
	handleCloseSnackbar(event) {
		this.setState({
			snackbarClosed: true
		});
	}
	
	handleLogin(event) {
		const { t } = this.props;

		event.preventDefault();
		
		this.props.context.showActivityIndicator();

		const username = this.state.username;
		const password = this.state.password;
		
		//console.log("Username: " + username + ", Password: " + password);
		
		const query = 'mutation login(' +
			'  $username: String!' +
			'  $password: String!' +
			'){' +
			'  login(' +
			'    username: $username' +
			'    password: $password' +
			'  )' +
			'}';
		
		let variables = {
			"username": username,
			"password": password
    	};
		let request = JSON.stringify({query: query, variables: variables});
		fetch(this.props.context.graphqlEndpoint, {
			method: "POST",
			body: request
		})
		.then(response => response.json())
		.then(json => {
			if (json.errors != null) {
				console.log(json.errors);
			}
			else {
				if (json.data.login == null) {
					this.setState({
						errorMessage: t('incorrectUsernameOrPassword'),
						snackbarClosed: false
					});
					console.log("Login error");
					this.props.context.setState({
						username: null,
						accessToken: null,
						model: null
					});
					this.props.context.hideActivityIndicator();
				}
				else {
					const query2 = '{ model: getModel }';
					
					let variables2 = {
						"authorization": json.data.login
			    	};
					let request2 = JSON.stringify({query: query2, variables: variables2});
					fetch(this.props.context.graphqlEndpoint, {
						method: "POST",
						body: request2
					})
					.then(response => response.json())
					.then(json2 => {
						if (json2.errors != null) {
							console.log(json2.errors);
						}
						else {
							// localModel & i18n
							const query3 = '{ '
								+ '   externalEntities: Models_ExternalEntityList { '
								+ '	    schema '
								+ '	    name '
								+ '     url '
								+ '	    order '
								+ '	    menu '
								+ '	    icon '
								+ '     labels: ExternalEntityLabelListViaContainer { '
								+ '       locale '
								+ '       label '
								+ '       pluralLabel '
								+ '     } '
								+ '	  } '
								+ '   entities: Models_EntityList { '
								+ '	    schema '
								+ '	    name '
								+ '	    order '
								+ '	    menu '
								+ '	    icon '
								+ '     labels: EntityLabelListViaContainer { '
								+ '       locale '
								+ '       label '
								+ '       pluralLabel '
								+ '     } '
								+ '	    attributes: EntityAttributeListViaContainer { '
								+ '	      name '
								+ '	      order '
								+ '	      type '
								+ '	      customType '
								+ '	      enumType '
								+ '	      required: isRequired '
								+ '	      label: isLabel '
								+ '	      list: isList '
								+ '	      visible: isVisible '
								+ '	      basicFilter: isBasicFilter '
								+ '	      lastInRow: isLastInRow '
								+ '	      eventStart: isEventStart '
								+ '	      eventEnd: isEventEnd '
								+ '	      pointInMap: isPointInMap '
								+ '	      computed: isComputed '
								+ '	      array: isArray '
								+ '	      tab '
								+ '	      group '
								+ '	      xs '
								+ '	      sm '
								+ '       length '
								+ '	      multiline: isMultiline '
								+ '       precision '
								+ '	      scale '
								+ '	      min '
								+ '	      max '
								+ '	      step '
								+ '       pattern '
								+ '       password: isPassword '
								+ '	      acceptedFileTypes '
								+ '	      maxFiles '
								+ '	      defaultValue '
								+ '	      labels: EntityAttributeLabelListViaContainer { '
								+ '	        locale '
								+ '	        label '
								+ '	      } '
								+ '	    } '
								+ '	    tabs: EntityTabListViaContainer { '
								+ '	      id '
								+ '	      name '
								+ '	      order '
								+ '	      labels: EntityTabLabelListViaContainer { '
								+ '	        locale '
								+ '	        label '
								+ '	      } '
								+ '	    } '
								+ '	    groups: EntityGroupListViaContainer { '
								+ '	      id '
								+ '	      name '
								+ '	      order '
								+ '	      labels: EntityGroupLabelListViaContainer { '
								+ '	        locale '
								+ '	        label '
								+ '	      } '
								+ '	    } '
								+ '     directReferences: EntityReferenceListViaContainer { '
								+ '       name '
								+ '       referencedKey: EntityKeyViaReferencedKey { '
								+ '         entity: EntityViaContainer { '
								+ '           name '
								+ '         } '
								+ '       } '
								+ '       attributes: EntityReferenceAttributeListViaContainer { '
								+ '         attribute: EntityAttributeViaAttribute { '
								+ '           name '
								+ '         } '
								+ '       } '
								+ '       visible: isVisible '
								+ '       list: isList '
								+ '       label: isLabel '
								+ '       order '
								+ '       tab '
								+ '       group '
								+ '       xs '
								+ '       sm '
								+ '     } '
								+ '	    inverseReferences: EntityKeyListViaContainer { '
								+ '       references: EntityReferenceListViaReferencedKey { '
								+ '         name '
								+ '         entity: EntityViaContainer { '
								+ '           name '
								+ '         } '
								+ '         attributes: EntityReferenceAttributeListViaContainer { '
								+ '           attribute: EntityAttributeViaAttribute { '
								+ '             name '
								+ '           } '
								+ '         } '
								+ '         visible: listIsVisible '
								+ '         filteredWhenEmpty: listIsFilteredWhenEmpty '
								+ '         order: listOrder '
								+ '         tab: listTab '
								+ '         group: listGroup '
								+ '         xs: listXs '
								+ '       	sm: listSm '
								+ '       } '
								+ '     } '
								+ '	  } '
								+ '   enumTypes: Models_EnumTypeList { '
								+ '	    schema '
								+ '	    name '
								+ '     values '
								+ '     labels: EnumTypeLabelListViaContainer { '
								+ '       locale '
								+ '       labels '
								+ '     } '
								+ '   } '
								+ '}';
							
							const variables3 = {
					    		authorization: json.data.login
					    	};
							let request3 = JSON.stringify({query: query3, variables: variables3});
							
							fetch(this.props.context.graphqlEndpoint, {
								method: "POST",
								body: request3
							})
							.then(response => response.json())
							.then(json3 => {
								if (json3.data != null && json3.data.enumTypes != null) {
									json3.data.enumTypes.forEach(enumType => {
										if (enumType.labels != null) {
											enumType.labels.forEach(label => {
												if (resources[label.locale.substr(0, 2)].translation.enums[enumType.schema] == null) {
													resources[label.locale.substr(0, 2)].translation.enums[enumType.schema] = {};
												}
												resources[label.locale.substr(0, 2)].translation.enums[enumType.schema][enumType.name] = {
													v: {}
												};
												label.labels.forEach((valueLabel, i) => {
													resources[label.locale.substr(0, 2)].translation.enums[enumType.schema][enumType.name].v[enumType.values[i]] = valueLabel;
												});
											});
										}
									});
								}

								if (json3.data != null && json3.data.externalEntities != null) {
									json3.data.externalEntities.forEach(entity => {
										
										localModel.entities[entity.schema + "." + entity.name] = {
											icon: entity.icon,
											menu: entity.menu,
											order: entity.order,
											external: true,
											url: entity.url,
										};

										if (entity.labels != null) {
											entity.labels.forEach(label => {
												if (resources[label.locale.substr(0, 2)].translation.e[entity.schema] == null) {
													resources[label.locale.substr(0, 2)].translation.e[entity.schema] = {};
												}
												resources[label.locale.substr(0, 2)].translation.e[entity.schema][entity.name] = {
													name: label.label,
													pluralName: label.pluralLabel,
													a: {}
												};
											});
										}
									});
								}
								
								if (json3.data != null && json3.data.entities != null) {
									json3.data.entities.forEach(entity => {
										let attributes = {};
										if (entity.attributes != null) {
											entity.attributes.forEach(attribute => {
												attributes[attribute.name] = attribute;
											});
										}
										
										let tabs = null;
										if (entity.tabs != null) {
											tabs = {};
											entity.tabs.forEach(tab => {
												tabs[tab.id] = tab;
											});
										}
										
										let groups = null;
										if (entity.groups != null) {
											groups = {};
											entity.groups.forEach(group => {
												groups[group.id] = group;
											});
										}
										
										localModel.entities[entity.schema + "." + entity.name] = {
											icon: entity.icon,
											menu: entity.menu,
											order: entity.order,
											attributes: attributes,
											tabs: tabs,
											groups: groups,
										};
										
										if (entity.labels != null) {
											entity.labels.forEach(label => {
												if (resources[label.locale.substr(0, 2)].translation.e[entity.schema] == null) {
													resources[label.locale.substr(0, 2)].translation.e[entity.schema] = {};
												}
												resources[label.locale.substr(0, 2)].translation.e[entity.schema][entity.name] = {
													name: label.label,
													pluralName: label.pluralLabel,
													a: {}
												};
											});
											
											if (entity.attributes != null) {
												entity.attributes.forEach(attribute => {
													if (attribute.labels != null) {
														attribute.labels.forEach(label => {
															if (resources[label.locale.substr(0, 2)].translation.e[entity.schema] == null) {
																resources[label.locale.substr(0, 2)].translation.e[entity.schema] = {};
															}
															if (resources[label.locale.substr(0, 2)].translation.e[entity.schema][entity.name] == null) {
																resources[label.locale.substr(0, 2)].translation.e[entity.schema][entity.name] = {a:{}};
															}
															resources[label.locale.substr(0, 2)].translation.e[entity.schema][entity.name].a[attribute.name] = label.label;
														});
													}
												});
											}
										}

										if (entity.tabs != null) {
											entity.tabs.forEach(tab => {
												if (tab.labels != null) {
													tab.labels.forEach(label => {
														if (resources[label.locale.substr(0, 2)].translation.e[entity.schema] == null) {
															resources[label.locale.substr(0, 2)].translation.e[entity.schema] = {};
														}
														if (resources[label.locale.substr(0, 2)].translation.e[entity.schema][entity.name] == null) {
															resources[label.locale.substr(0, 2)].translation.e[entity.schema][entity.name] = {};
														}
														if (resources[label.locale.substr(0, 2)].translation.e[entity.schema][entity.name].tabs == null) {
															resources[label.locale.substr(0, 2)].translation.e[entity.schema][entity.name].tabs = {};
														}
														resources[label.locale.substr(0, 2)].translation.e[entity.schema][entity.name].tabs["" + tab.id] = label.label;
													});
												}
											});
										}
										
										if (entity.groups != null) {
											entity.groups.forEach(group => {
												if (group.labels != null) {
													group.labels.forEach(label => {
														if (resources[label.locale.substr(0, 2)].translation.e[entity.schema] == null) {
															resources[label.locale.substr(0, 2)].translation.e[entity.schema] = {};
														}
														if (resources[label.locale.substr(0, 2)].translation.e[entity.schema][entity.name] == null) {
															resources[label.locale.substr(0, 2)].translation.e[entity.schema][entity.name] = {};
														}
														if (resources[label.locale.substr(0, 2)].translation.e[entity.schema][entity.name].groups == null) {
															resources[label.locale.substr(0, 2)].translation.e[entity.schema][entity.name].groups = {};
														}
														resources[label.locale.substr(0, 2)].translation.e[entity.schema][entity.name].groups["" + group.id] = label.label;
													});
												}
											});
										}
										if (entity.directReferences != null) {
											entity.directReferences.forEach(reference => {
												if (reference.attributes != null) {
													localModel.entities[entity.schema + "." + entity.name].attributes[reference.referencedKey.entity.name + "Via" + reference.name.substr(0, 1).toUpperCase() + reference.name.substr(1)] = {
														list: reference.list,
														visible: reference.visible,
														label: reference.label,
														order: reference.order,
														tab: reference.tab,
														group: reference.group,
														xs: reference.xs,
														sm: reference.sm,
													};
												}
											});
										}
										if (entity.inverseReferences != null) {
											entity.inverseReferences.forEach(key => {
												if (key.references != null) {
													key.references.forEach(reference => {
														if (reference.attributes != null) {
															localModel.entities[entity.schema + "." + entity.name].attributes[reference.entity.name + "ListVia" + reference.name.substr(0, 1).toUpperCase() + reference.name.substr(1)] = {
																visible: reference.visible,
																filteredWhenEmpty: reference.filteredWhenEmpty,
																order: reference.order,
																tab: reference.tab,
																group: reference.group,
																xs: reference.xs,
																sm: reference.sm,
															};
														}
													});
												}
											});
										}
									});
								}

								let model = JSON.parse(json2.data.model);
								
								if (model.entities != null) {
									Object.values(model.entities).forEach(entity => {
										if (entity.references != null) {
											Object.values(entity.references).forEach(reference => {
												reference.referenceAttributeName = reference.referencedKey.entityName.split(".")[1] + "Via" + reference.name.substring(0, 1).toUpperCase() + reference.name.substring(1);
												reference.incomingReferenceAttributeName = reference.entityName.split(".")[1] + "ListVia" + reference.name.substring(0, 1).toUpperCase() + reference.name.substring(1);
											});
										}
									});
								}
								
								console.log("Model:");
								console.log(model);
								console.log("Local model:");
								console.log(localModel);
								
								this.props.context.setState({
									username: username,
									accessToken: json.data.login,
									model: model,
									localModel: localModel,
									resources: resources,
								});
								this.props.context.hideActivityIndicator();
							});
						}
					});
				}
			}
		});
	}
	
	handleChange(event) {
		const target = event.target;
		const value = (target.type === 'checkbox' ? target.checked : target.value);
		this.setState({
			[target.name]: value
		});
	}
	
	render() {
		const { classes, t } = this.props;
		
		return (
			<React.Fragment>
				<CssBaseline />
				
				<AppContext.Consumer> 
					{(context) => (context.accessToken != null ? <Redirect to="/admin"/> : null)}
				</AppContext.Consumer>
				
				<Snackbar
						data-qa="error-message"
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						autoHideDuration={5000}
						onClose={this.handleCloseSnackbar}
						open={!this.state.snackbarClosed}>
					<SnackbarContent
							className={classes.snackbar}
							message={<><span className={classes.message}><ErrorIcon className={classes.icon}/>{this.state.errorMessage}</span></>}
							
					/>
				</Snackbar>
				
				<main className={classes.layout}>
					<Paper className={classes.paper}>
					<img src="/customer-logo.png" style={{width: "140px"}}/>
						<Avatar className={classes.avatar}>
							<LockIcon />
						</Avatar>
						<Typography variant="h5">
							{t('accessControl')}
						</Typography>
						<form className={classes.form} onSubmit={this.handleLogin}>
							<FormControl margin="normal" required fullWidth>
								<InputLabel htmlFor="username">{t('username')}</InputLabel>
								<Input 	inputProps={{"data-qa": "username-input"}}
										id="username" 
										name="username" 
										autoComplete="username" 
										value={this.state.username} 
										onChange={this.handleChange} 
										autoFocus/>
							</FormControl>

							<FormControl margin="normal" required fullWidth>
								<InputLabel htmlFor="password">{t('password')}</InputLabel>
								<Input 	inputProps={{"data-qa": "password-input"}}
										type="password" 
										id="password" 
										name="password" 
										autoComplete="current-password" 
										value={this.state.password} 
										onChange={this.handleChange}/>
							</FormControl>

							<FormControl margin="normal" required fullWidth>
								<InputLabel htmlFor="language">{t('language')}</InputLabel>
								<NativeSelect
										id="language" 
										name="language"
										value={this.state.language}
										onChange={this.handleLanguageChange}
										inputProps={{
											name: 'Idioma',
											"data-qa": "language-input"
										}}>
									<option value="es">Español</option>
									<option value="en">English</option>
								</NativeSelect>
							</FormControl>
						
							<Button data-qa="login-button" type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
								{t('login')}
							</Button>
						</form>
					</Paper>
				</main>
				<div style={{position: "fixed", right: "10px", bottom: "10px", display: "flex", alignItems: "flex-end"}}>
					<Typography
							variant="body2"
							noWrap>
						Powered by&nbsp;
					</Typography>
					<a href="https://niledb.com" target="_blank"><img src="/logo.png" className={classes.icon} style={{width: "90px"}}/></a>
				</div>
			</React.Fragment>
		);
	}
}

SignIn.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withContext(withI18n()(SignIn)));

import React, { Component } from 'react';
import history from './history';

import Home from './Home';
import SignIn from './SignIn';
import Dashboard from './Dashboard';

import { Router, Route, Redirect, Switch } from 'react-router-dom';

import { withI18n } from 'react-i18next';

import './i18n';

import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles } from '@material-ui/core/styles';

import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';

// Application context (i.e. application model, i18n labels, selected language, current user, GraphQL endpoint, ...).
export const AppContext = React.createContext();

const styles = theme => ({
	circularProgress: {
		position: "fixed",
		left: "50%",
		top: "50%",
		zIndex: 10001,
	},
	circleIndeterminate: {
		animation: 'mui-progress-circular-dash 0s ease-in-out infinite',
		// Some default value that looks fine waiting for the animation to kicks in.
		strokeDasharray: '80px, 200px',
		strokeDashoffset: '0px' // Add the unit to fix a Edge 16 and below bug.
	},
});

class App extends Component {
	
	// Constructor is called twice in development mode in Chrome (it is the expected behavior when StrictMode)
	// https://reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects
	constructor(props) {
		super(props);
		//console.log(">> App.constructor");

		this.state = {
			//graphqlEndpoint: "https://localhost:8443/graphql",
			graphqlEndpoint: "/graphql",
			accessToken: null,
			
			open: false,
			handleDrawerOpen: () => {
				this.setState({ open: true });
			},
			handleDrawerClose: () => {
				this.setState({ open: false });
			},
			
			activityIndicatorVisible: false,
			showActivityIndicator: () => {
				this.setState({
					activityIndicatorVisible: true
				});
			},
			hideActivityIndicator: () => {
				this.setState({
					activityIndicatorVisible: false
				});
			},
			
			setState: (state) => {
				this.setState(state);
			}
		};
		
		this.handleLogout = this.handleLogout.bind(this);
		this.handleLoginClick = this.handleLoginClick.bind(this);
	}
	
	handleLoginClick(event) {
		history.push("/login");
	}
	
	handleLogout(event) {
		this.setState({
			"accessToken": null
		})
	}
	
	/*
	componentWillMount() {
		// En principio voy a prescindir de guardar el estado en local, si refresco la página envío el usuario al login
		//    para que vuelva a cargar su modelo, el modelo local, las etiquetas, el token de acceso, ....
		// No recuerdo por qué hacía falta guardar el contexto en local, creo que había un problema con la navegación que ya no se da.
		// Además, el método componentWillMount está deprecado.
		// Lo dejaré comentado durante un tiempo para verificar que la aplicación funciona correctamente.
		// Reload context from local storage
		var storedContext = localStorage.getItem("context");
		if (storedContext != null) {
			this.setState(JSON.parse(storedContext));
		}
	}
	*/
	
	render() {
		const { classes } = this.props;
		
		return (
			<AppContext.Provider value={this.state}>
				<AppContext.Consumer> 
					{(context) => {
						// Leeer el otro comentario donde se lee del almacenamiento local
						//console.log(context);
						//localStorage.setItem("context", JSON.stringify(context));
					}}
				</AppContext.Consumer>
				
				<Router history={history}>
					<Switch>
						<Route path="/" exact strict render={props => {
							return (
								<Redirect to="/login"/>
								//<Home onLoginClick={this.handleLoginClick}/>
							);
						}}/>
						<Route path="/tools/:toolName" exact strict render={props => (this.state.model != null 
								&& 
								<Dashboard/>
							)
							||
							<Redirect to="/login"/>
						}/>
						<Route path="/login" exact strict render={props => {
							return (
								<SignIn onSubmit={this.handleLogin}/>
							);
						}}/>
						<Route path="/admin" exact strict render={props => (this.state.model != null 
								&& 
								<Dashboard/>
							)
							||
							<Redirect to="/login"/>
						}/>
						<Route path="/admin/:entityName" exact strict render={props => (this.state.model != null 
								&& 
								<Dashboard/>
							)
							||
							<Redirect to="/login"/>
						}/>
						<Route path="/admin/:entityName/external" exact strict render={props => (this.state.model != null 
								&& 
								<Dashboard/>
							)
							||
							<Redirect to="/login"/>
						}/>
						<Route path="/admin/:entityName/new" exact strict render={props => (this.state.model != null 
								&& 
								<Dashboard/>
							)
							||
							<Redirect to="/login"/>
						}/>
						<Route path="/admin/:entityName/new/:preselectedAttribute/:preselectedValue" exact strict render={props => (this.state.model != null 
								&& 
								<Dashboard/>
							)
							||
							<Redirect to="/login"/>
						}/>
						<Route path="/admin/:entityName/:entityId/view" exact strict render={props => (this.state.model != null 
								&& 
								<Dashboard/>
							)
							||
							<Redirect to="/login"/>
						}/>
						<Route path="/admin/:entityName/:entityId/edit" exact strict render={props => (this.state.model != null 
								&& 
								<Dashboard/>
							)
							||
							<Redirect to="/login"/>
						}/>
						<Route render={props => <Redirect to="/"/>}/>
					</Switch>
				</Router>
				<AppContext.Consumer> 
					{(context) => {return context.activityIndicatorVisible && (
						<CircularProgress classes={{circleIndeterminate: classes.circleIndeterminate}} className={classes.circularProgress}></CircularProgress>
					)}}
				</AppContext.Consumer>
			</AppContext.Provider>
		);
	}
}

// Each time context changes it is assigned to props.context using this HOC (Higher-order component) wrapper.
export function withContext(Component) {
	return function WithContextComponent(props) {
		return (
			<AppContext.Consumer>
				{ context => <Component {...props} context={context}/> }
			</AppContext.Consumer>
		);
	};
}

export default withStyles(styles)(withContext(withI18n()(App)));

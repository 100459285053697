import { createMuiTheme } from '@material-ui/core/styles';
//import red from '@material-ui/core/colors/red';
//import pink from '@material-ui/core/colors/pink';
//import purple from '@material-ui/core/colors/purple';
//import deepPurple from '@material-ui/core/colors/deepPurple';
//import indigo from '@material-ui/core/colors/indigo';
//import blue from '@material-ui/core/colors/blue';
//import lightBlue from '@material-ui/core/colors/lightBlue';
//import cyan from '@material-ui/core/colors/cyan';
//import teal from '@material-ui/core/colors/teal';
//import green from '@material-ui/core/colors/green';
//import lightGreen from '@material-ui/core/colors/lightGreen';
//import lime from '@material-ui/core/colors/lime';
//import yellow from '@material-ui/core/colors/yellow';
//import amber from '@material-ui/core/colors/amber';
//import orange from '@material-ui/core/colors/orange';
//import deepOrange from '@material-ui/core/colors/deepOrange';
import grey from '@material-ui/core/colors/grey';
//import blueGrey from '@material-ui/core/colors/blueGrey';
//import brown from '@material-ui/core/colors/brown';

// We can override palette, ... here to customize the theme to our branding requirements
export const theme = createMuiTheme({
	overrides: {
		MuiInput: {
			root: {
				'&$disabled': {
					color: grey[800],
				}
			}
		},
		MuiFormLabel: {
			root: {
				'&$disabled': {
					color: grey[600],
				}
			}
		},
	},
	/*
	overrides: {
		MuiIconButton: {
			root: {
				'&:hover': {
					backgroundColor: grey[400],
				}
			}
		},
	},
	*/
	palette: {
		primary: {
			main: "#008c2e",
		},
		secondary: {
			main: "#707070",
		},
	},
	typography: {
		useNextVariants: true,
	},
});

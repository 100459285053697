import { throttle, debounce } from "throttle-debounce";
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withContext } from './App';
import { withI18n } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { localModel } from './localModel';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import history from './history';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import AutoComplete from './AutoComplete';

import LogiFilterBuilder from "./LogiFilterBuilder";

import BigCalendar from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import 'moment/locale/es';

import ListIcon from '@material-ui/icons/List';
import EventIcon from '@material-ui/icons/Event';
import MapIcon from '@material-ui/icons/Map';

import FilterListIcon from '@material-ui/icons/FilterList';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

import Modeler from 'bpmn-js/lib/Modeler';

import FormControlLabel from '@material-ui/core/FormControlLabel';

const localizer = BigCalendar.momentLocalizer(moment);

const styles = theme => ({
	spacer: {
		flex: "1 1 1%",
	},
	title: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing.unit * 2,
		[theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
			padding: theme.spacing.unit * 3,
		},
	},
	checkbox: {
		width: "1px",
	},
	fab: {
		position: 'fixed',
		zIndex: 1000,
		bottom: theme.spacing.unit * 2,
		right: theme.spacing.unit * 2,
	},
	root: {
		width: '100%',
		overflowX: 'auto',
		overflowY: 'auto',
	},
	table: {
		minWidth: 700,
	},
	tableHeader: {
		whiteSpace: "nowrap",
	},
	tableRow: {
		cursor: "pointer",
	},
	toolbarTitle: {
		flex: 1,
		marginBottom: theme.spacing.unit * 2,
	},
	toolbar: {
	},
	toolbar2: {
		color: theme.palette.secondary.contrastText,
		backgroundColor: theme.palette.secondary.dark,
	},
	actionToolbar: {
		flex: "0 0 auto",
	},
	action: {
		color: theme.palette.secondary.contrastText,
		"&$disabledAction": {
			color: theme.palette.secondary.light,
		}
	},
	disabledAction: {
	},
	subheading: {
		flexGrow: 1,
	},
	noData: {
		flex: 1,
		margin: theme.spacing.unit * 2,
	},
	input: {
		margin: theme.spacing.unit,
	},
	snackbar: {
	},
	snackbarError: {
		backgroundColor: theme.palette.error.dark,
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		fontSize: 20,
		marginRight: theme.spacing.unit,
	},
	anchor: {
		color: theme.palette.primary.main,
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		marginRight: theme.spacing.unit * 2,
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing.unit * 3,
			width: 'auto',
		},		
	},
	toggleContainer: {
		display: "inline-flex",
		margin: theme.spacing.unit,
	},
	calendar: {
		height: "700px",
	},
	bigCalendar: {
		padding: theme.spacing.unit * 3,
		fontFamily: "Roboto, Helvetica, Arial, sans-serif",
	},
});

class EntityList extends Component {
	
	constructor(props) {
		super(props);
		// console.log(">> EntityList.constructor");

		let entityLocalModel = localModel.entities[props.entity]
		let basicFilters = {};
		Object.keys(entityLocalModel.attributes)
				.filter(attributeName => entityLocalModel.attributes[attributeName].filteredWhenEmpty)
				.forEach(attributeName => {
					basicFilters[attributeName] = true;
				});
		
		this.state = {
			currentEntity: null,
			data: null,
			deleteConfirmationDialogOpened: false,
			filtersEnabled: false,
			basicFiltersEnabled: false,
			orderBy: null,
			orderDirection: "asc",
			limit: 20,
			mode: "table",
			
			latitude: 40.5290087,
			longitude: -3.6537882,
			
			basicFilters: basicFilters,
		};


						


		
		this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
		this.handleAnchorClick = this.handleAnchorClick.bind(this);
		this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
		this.handleRowClick = this.handleRowClick.bind(this);
		this.handleNewClick = this.handleNewClick.bind(this);
		this.handleViewClick = this.handleViewClick.bind(this);
		this.handleEditClick = this.handleEditClick.bind(this);
		this.handleDeleteClick = this.handleDeleteClick.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleSortClick = this.handleSortClick.bind(this);
		this.handleEnableFilters = this.handleEnableFilters.bind(this);
		this.handleEnableBasicFilters = this.handleEnableBasicFilters.bind(this);
		this.refreshData = this.refreshData.bind(this);
		this.handleSearchKeyDown = this.handleSearchKeyDown.bind(this);
		this.handleSearchClick = this.handleSearchClick.bind(this);

		this.handleChangeMode = this.handleChangeMode.bind(this);
		this.handleToggleBasicFilters = this.handleToggleBasicFilters.bind(this);

		this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
		
		this.refreshDataThrottled = throttle(500, this.refreshData);
		this.handleDeleteDebounced = debounce(3000, true, this.handleDelete);

		this.searchInput = React.createRef();
		
		// TODO Habrá que poner una propiedad enableScroll para habilitar esto,
		// en los detalles estará deshabilitado.
		// TODO También habrá un botón que podrás pulsar o que saldrá después de
		// varios scrolls (como en twitter).
		if (this.props.main) {
			window.addEventListener("scroll", this.updateDimensions.bind(this));
		}
	}
	
	// Event handlers
	
	updateDimensions(event) {
		if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 1) {
			if (this.state.limit < 100) {
				if (this._isMounted) {
					this.setState((state, props) => ({
						limit: Math.min(120, state.limit + 20),
					}), () => this.refreshDataThrottled());
				}
			}
		}
	}
	
	handleToggleBasicFilters(event, mode) {
		this.setState({
			basicFiltersEnabled: mode,
		});
	}
	
	handleCheckboxChange(event, checked, attributeName) {
		//console.log(">> EntityView.handleDeleteChip")
		this.setState((state, props) => {
			state.basicFilters[attributeName] = checked;
			return {
				basicFilters: state.basicFilters,
			};
		},  () => this.handleSearchClick(event));
	}
	
	handleChangeMode(event, mode) {
		if (mode != null) {
			this.setState({
				mode: mode,
			});
		}
	}
	
	handleSearchKeyDown(event) {
		if (event.keyCode === 13) {
			this.setState({
				limit: 20,
			}, () => this.refreshDataThrottled());
		}
	}
	
	handleSearchClick(event) {
		this.setState({
			limit: 20,
		}, () => this.refreshDataThrottled());
	}
	
	handleEnableFilters(event) {
		this.setState((state, props) => ({
			filtersEnabled: !state.filtersEnabled
		}));
	}
	
	handleEnableBasicFilters(event) {
		this.setState((state, props) => ({
			basicFiltersEnabled: !state.basicFiltersEnabled
		}));
	}
	
	handleSelectAllClick(event, checked) {
		this.state.data.map(item => item._selected = checked);
		this.setState((state, props) => ({data: state.data}));
	}
	
	handleRowClick(event, item) {
		event.stopPropagation();
		history.push("/admin/" + this.props.entity + '/' + item[this.state.keyAttribute.name] + "/view");
	}
	
	handleCheckboxClick(event, item) {
		event.stopPropagation();
		item._selected = !item._selected;
		this.setState((state, props) => ({
			data: state.data,
		}));
	}
	
	handleAnchorClick(event, item, attribute) {
		event.preventDefault();
		event.stopPropagation();
		const model = this.props.context.model;
		let keyAttribute = Object.values(model.entities[attribute.referencedKey.entityName].keys).filter(key => key.primaryKey)[0].attributes[0];
		history.push("/admin/" + attribute.referencedKey.entityName + "/" + item[attribute.referenceAttributeName][keyAttribute.name] + "/view");
	}
	
	handleNewClick(event) {
		if (this.props.filterAttribute == null) {
			history.push("/admin/" + this.props.entity + "/new");
		}
		else {
			history.push("/admin/" + this.props.entity + "/new/" + this.props.filterAttribute + "/" + this.props.filterValue);
		}
	}
	
	handleViewClick(event) {
		history.push("/admin/" + this.props.entity + '/' + this.state.data.filter(item => item._selected)[0][this.state.keyAttribute.name] + "/view");
	}
	
	handleEditClick(event) {
		history.push("/admin/" + this.props.entity + '/' + this.state.data.filter(item => item._selected)[0][this.state.keyAttribute.name] + "/edit");
	}
	
	handleDeleteClick(event) {
		this.setState({
			deleteConfirmationDialogOpened: true,
		});
	}
	
	handleDelete(event) {
		this.props.context.showActivityIndicator();
		const selectedItems = this.state.data.filter(item => item._selected);
		
		let query = 'mutation { ' + this.props.entity.replace(".", "_") + 'Delete( where: { ' + this.state.keyAttribute.name + ': { IN: [' 
				+ selectedItems.map(item => this.setQuotes(this.state.keyAttribute, item[this.state.keyAttribute.name])).toString() 
				+ '] }}) { ' + this.state.keyAttribute.name + ' }}';
		
		const variables = {
    		authorization: this.props.context.accessToken
    	};
    	
		// console.log("Query: " + query);
		
		let request = JSON.stringify({query: query, variables: variables});
		fetch(this.props.context.graphqlEndpoint, {
			method: "POST",
			body: request
		})
		.then(response => response.json())
		.then(json => {
			// This is considered an anti-pattern
			if (this._isMounted) {
				if (json.errors != null) {
					this.setState({
						message: json.errors[0].message,
						messageError: true,
						messageOpened: true,
						deleteConfirmationDialogOpened: false,
					}, () => this.refreshDataThrottled());
				}
				else {
					this.setState({
						message: selectedItems.length + " " + this.props.t('deleteSuccess'),
						messageError: false,
						messageOpened: true,
						deleteConfirmationDialogOpened: false,
					}, () => this.refreshDataThrottled());
				}
			}
			else {
				this.props.context.hideActivityIndicator();
			}
		});
	}
	
	handleSortClick(event, columnName) {
		if (this.state.orderBy !== columnName) {
			this.setState({
				orderBy: columnName,
				orderDirection: 'asc',
			}, () => this.refreshDataThrottled());
		}
		else {
			this.setState((state, props) => ({
				orderBy: columnName,
				orderDirection: (state.orderDirection === 'asc' ? 'desc' : 'asc'),
			}), () => this.refreshDataThrottled());
		}
	}
	
	// Life cycle methods
	
	componentDidMount() {
		// console.log(">> EntityList.componentDidMount");
		this._isMounted = true;
		this.refreshDataThrottled();
		
		/*
		const modelerContainer = document.querySelector("#modeler-container");
		
		let modeler = new Modeler({
			container: modelerContainer
		})
		
		modeler.createDiagram(() => {
			console.log("ya!!");
		});
		*/
	}
	
	componentDidUpdate(prevProps) {
		// console.log(">> EntityList.componentDidUpdate");
		if (this.props.entity !== prevProps.entity
				|| this.props.filterAttribute !== prevProps.filterAttribute
				|| this.props.filterValue !== prevProps.filterValue) {
			window.scrollTo(0, 0);
			this.setState({
				limit: 20,
				orderBy: null,
				orderDirection: 'asc',
				mode: 'table',
			}, () => this.refreshDataThrottled());
		}
	}
	
	componentWillUnmount() {
		//console.log(">> EntityList.componentWillUnmount");
		this._isMounted = false;
	}
	
	// Other methods
	
	setQuotes(attribute, value) {
		const hasQuotes = (attribute.type === "TEXT"
			|| attribute.type === "DATE"
			|| attribute.type === "TIMESTAMP"
			|| attribute.type === "VARCHAR"
			|| attribute.type === "CHAR"
			|| attribute.type === "TIME"
			|| attribute.type === "INTERVAL"
			|| attribute.type === "TIMESTAMP_WITH_TIME_ZONE"
			|| attribute.type === "TIME_WITH_TIME_ZONE"
			|| attribute.type === "POINT"
			|| attribute.type === "POLYGON"
		);
		return (hasQuotes ? '"' : '') + value + (hasQuotes ? '"' : '');
	}
	
	getLabelAttributesQueryString(entityModel, entityLocalModel, exceptionAttribute, depth) {
		let str = "";

		if (depth == null) {
			depth = 1;
		}
		
		Object.values(entityModel.attributes)
			.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].label)
			.forEach(attribute => {
				if (exceptionAttribute == null || attribute.name != exceptionAttribute.name) {
					str += attribute.name + ", ";
				}
			});
		
		if (depth < 5) {
			Object.values(entityModel.references)
				.filter(reference => reference.referenceAttributeName != null && entityLocalModel.attributes[reference.referenceAttributeName].label)
				.forEach(reference => {
					const model = this.props.context.model;
					str += reference.referenceAttributeName + "{ " 
							+ this.getLabelAttributesQueryString(model.entities[reference.referencedKey.entityName], localModel.entities[reference.referencedKey.entityName], null, depth + 1)
							+ "}, ";
				});
		}
		
		return str;
	}
	
	getLabel(item, entityModel, entityLocalModel) {
		let label = "";

		if (item == null) {
			return null;
		}
		
		const model = this.props.context.model;

		let labels = Object.values(entityModel.attributes)
			.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].label)
			.map(attribute => {
				return {
					order: entityLocalModel.attributes[attribute.name].order,
					label: item[attribute.name],
				}
			});
		
		Object.values(entityModel.references)
			.filter(reference => reference.referenceAttributeName != null && entityLocalModel.attributes[reference.referenceAttributeName].label)
			.forEach(reference => {
				labels.push({
					order: entityLocalModel.attributes[reference.referenceAttributeName].order,
					label: this.getLabel(item[reference.referenceAttributeName], model.entities[reference.referencedKey.entityName], localModel.entities[reference.referencedKey.entityName])
				});
			});
		
		return labels.sort((a, b) => (a.order == null ? Infinity : a.order) - (b.order == null ? Infinity : b.order)).filter(label => label != null && label.label != null).map(label => label.label).join(", ");
	}
	
	refreshData() {
		// console.log(">> EntityList.refreshData")
		this.props.context.showActivityIndicator();
		
		const model = this.props.context.model;
		
		const entityModel = model.entities[this.props.entity];
		const entityLocalModel = localModel.entities[this.props.entity];
		
		let attributes = Object.values(entityModel.attributes)
				.filter(attribute => attribute.type !== "CUSTOM_TYPE" 
						&& entityLocalModel.attributes 
						&& entityLocalModel.attributes[attribute.name] 
						&& (
								entityLocalModel.attributes[attribute.name].list === undefined 
								|| entityLocalModel.attributes[attribute.name].list
						)
				);
		
		Object.values(entityModel.references)
				.filter(reference => { 
					return entityLocalModel.attributes[reference.referenceAttributeName] === undefined
							|| entityLocalModel.attributes[reference.referenceAttributeName].list === undefined
							|| entityLocalModel.attributes[reference.referenceAttributeName].list;
				})
				.forEach(reference => {
					attributes.push({
						name: reference.name,
						entityName: reference.entityName,
						referenceAttributeName: reference.referenceAttributeName,
						attributes: reference.attributes,
						referencedKey: reference.referencedKey,
						type: entityModel.attributes[reference.name].type,
					});
				});
		
		attributes = attributes.filter(attribute => this.props.filterAttribute === undefined || attribute.name !== this.props.filterAttribute);
		
		attributes.sort((a, b) => 
			(	
				entityLocalModel.attributes[a.referenceAttributeName || a.name] === undefined 
					|| entityLocalModel.attributes[a.referenceAttributeName || a.name].order === undefined 
						? Infinity 
						: entityLocalModel.attributes[a.referenceAttributeName || a.name].order
			) - (
				entityLocalModel.attributes[b.referenceAttributeName || b.name] === undefined 
					|| entityLocalModel.attributes[b.referenceAttributeName || b.name].order === undefined 
						? Infinity 
						: entityLocalModel.attributes[b.referenceAttributeName || b.name].order
			)
		);
		
		let keys = Object.values(entityModel.keys);
		const searchEnabled = keys.filter(key => key.textSearch).length > 0;
		let searchCriteria = null;
		let searchAttributeNames = [];
		if (searchEnabled) {
			searchCriteria = this.searchInput.current === null ? null : this.searchInput.current.value;
			searchCriteria = (searchCriteria === null || searchCriteria.replace(/[^Á-ÚA-Z0-9ñÑ\s"']/gi, '').trim() === "" ? null : searchCriteria.replace(/[^Á-ÚA-Z0-9ñÑ\s"']/gi, '').replace(/"/gi, "'").trim().split(/\s+/).join(":* &") + ":*");
			keys.filter(key => key.textSearch).map(key => Object.values(key.attributes).map(attribute => attribute.name in searchAttributeNames ? null : searchAttributeNames.push(attribute.name)));
		}

		//console.log("Attributes: ");
		//console.log(attributes);
		
		if (this.basicFilterRefs == null) {
			this.basicFilterRefs = {};
		}
		attributes
				.filter(attribute => entityLocalModel.attributes[attribute.name].basicFilter)
				.forEach(attribute => {
			if (attribute.incomingReferenceAttributeName === undefined)	{
				if (!attribute.array
						&& attribute.referenceAttributeName !== undefined) {
					if (this.basicFilterRefs[attribute.name] == null) {
						this.basicFilterRefs[attribute.name] = React.createRef();
					}
				}
				else if (!attribute.array
						&& (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR")) {
					if (this.basicFilterRefs[attribute.name] == null) {
						this.basicFilterRefs[attribute.name] = React.createRef();
					}
				}
				else if (!attribute.array
						&& (attribute.type === "INTEGER"
							|| attribute.type === "SMALLINT"
							|| attribute.type === "BIGINT"
							|| attribute.type === "SERIAL"
							|| attribute.type === "DECIMAL"
							|| attribute.type === "DOUBLE_PRECISION"
							|| attribute.type === "REAL"
							|| attribute.type === "MONEY"
							|| attribute.type === "SMALLSERIAL"
							|| attribute.type === "BIGSERIAL"
								
							|| attribute.type === "DATE")
							|| attribute.type === "TIMESTAMP") {
					if (this.basicFilterRefs[attribute.name + "From"] == null) {
						this.basicFilterRefs[attribute.name + "From"] = React.createRef();
						this.basicFilterRefs[attribute.name + "To"] = React.createRef();
					}
				}
			}	
		});

		let where = [];
		attributes
				.filter(attribute => entityLocalModel.attributes[attribute.name].basicFilter)
				.forEach(attribute => {
			
			if (!attribute.array
					&& attribute.referenceAttributeName !== undefined) {
				if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
					let value = (this.basicFilterRefs[attribute.name].current.select.state.value == null ? null : this.basicFilterRefs[attribute.name].current.select.state.value.value);
					if (value != null) {
						where.push('{' + attribute.name + ': {EQ: ' + value + '}}');
					}
				}
			}
			else if (!attribute.array
					&& (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR")) {
				if (this.basicFilterRefs[attribute.name].current != null) {
					if (this.basicFilterRefs[attribute.name].current.value != null
							&& this.basicFilterRefs[attribute.name].current.value != "") {
						let value = this.basicFilterRefs[attribute.name].current.value;
						value = (value.replace(/[^Á-ÚA-Z0-9ñÑ\s"']/gi, '').trim() === "" ? null : value.replace(/[^Á-ÚA-Z0-9ñÑ\s"']/gi, '').replace(/"/gi, "'").trim().split(/\s+/).join(":* &") + ":*");
						where.push('{' + attribute.name + ': {SEARCH: {query: "' + value + '" config: SPANISH}}}');
					}
				}
			}
			else if (!attribute.array
					&& (attribute.type === "INTEGER"
						|| attribute.type === "SMALLINT"
						|| attribute.type === "BIGINT"
						|| attribute.type === "SERIAL"
						|| attribute.type === "DECIMAL"
						|| attribute.type === "DOUBLE_PRECISION"
						|| attribute.type === "REAL"
						|| attribute.type === "MONEY"
						|| attribute.type === "SMALLSERIAL"
						|| attribute.type === "BIGSERIAL")) {
				if (this.basicFilterRefs[attribute.name + "From"].current != null) {
					if (this.basicFilterRefs[attribute.name + "From"].current.value != null
							&& this.basicFilterRefs[attribute.name + "From"].current.value != "") {
						let value = this.basicFilterRefs[attribute.name + "From"].current.value;
						where.push('{' + attribute.name + ': {GE: ' + Number(value) + '}}');
					}
					if (this.basicFilterRefs[attribute.name + "To"].current.value != null
							&& this.basicFilterRefs[attribute.name + "To"].current.value != "") {
						let value = this.basicFilterRefs[attribute.name + "To"].current.value;
						where.push('{' + attribute.name + ': {LE: ' + Number(value) + '}}');
					}
				}
			}
			else if (!attribute.array
					&& (attribute.type === "DATE" || attribute.type === "TIMESTAMP")) {
				if (this.basicFilterRefs[attribute.name + "From"].current != null) {
					if (this.basicFilterRefs[attribute.name + "From"].current.value != null
							&& this.basicFilterRefs[attribute.name + "From"].current.value != "") {
						let value = this.basicFilterRefs[attribute.name + "From"].current.value;
						where.push('{' + attribute.name + ': {GE: "' + value.replace("T", " ") + '"}}');
					}
					if (this.basicFilterRefs[attribute.name + "To"].current.value != null
							&& this.basicFilterRefs[attribute.name + "To"].current.value != "") {
						let value = this.basicFilterRefs[attribute.name + "To"].current.value;
						where.push('{' + attribute.name + ': {LE: "' + value.replace("T", " ") + '"}}');
					}
				}
			}
		});

		let orderBy = null;
		if (this.state.orderBy !== null) {
			orderBy = " orderBy: { attribute: " + this.state.orderBy + " direction: " + (this.state.orderDirection === 'asc' ? 'ASC' : 'DESC') + " nullsGo: LAST} ";
		}
		
		if (searchEnabled && searchCriteria != null) {
			where.push('{' + searchAttributeNames[0] + ': {SEARCH: {query: "' + searchCriteria + '" config: SPANISH}}}');
		}
		if (this.props.filterAttribute !== undefined && this.props.filterValue !== undefined) {
			where.push('{' + this.props.filterAttribute + ': {EQ: ' + this.setQuotes(entityModel.attributes[this.props.filterAttribute], this.props.filterValue) + '}}');
		}

		// Although several attributes can be added to the primary key in the API, 
		// we have simplified and assume only one in the administrative tool, because of the router, surrogate key, ...
		let primaryKeys = keys.filter(key => key.primaryKey);
		if (primaryKeys == null 
				|| primaryKeys.length == 0 
				|| primaryKeys.length > 1 
				|| primaryKeys[0].attributes == null
				|| primaryKeys[0].attributes.length == 0
				|| primaryKeys[0].attributes.length > 1) {
			
			this.setState({
				message: this.props.t('primaryKeyWithOnlyOneAttributeNeeded'),
				messageError: true,
				messageOpened: true,
				deleteConfirmationDialogOpened: false,
			}, () => this.props.context.hideActivityIndicator());
		}
		else {
			let keyAttribute = primaryKeys[0].attributes[0];
			
			const query = 
					'{ ' +
					'	result:' + this.props.entity.replace(".", "_") + 'List(' +
					'		limit: ' + this.state.limit +
					(orderBy != null ? orderBy : "") + 
					(where.length > 0 ? ' where: {AND: [' + where.join(" ") + ']}' : '') +
					'	) { ' + keyAttribute.name + ', ' +
					'   	' + attributes.map(attribute => {
							if (attribute.referenceAttributeName !== undefined) {
								let subPrimaryKeys = Object.values(model.entities[attribute.referencedKey.entityName].keys).filter(key => key.primaryKey);
								if (subPrimaryKeys == null 
										|| subPrimaryKeys.length == 0 
										|| subPrimaryKeys.length > 1 
										|| subPrimaryKeys[0].attributes == null
										|| subPrimaryKeys[0].attributes.length == 0
										|| subPrimaryKeys[0].attributes.length > 1) {
									
									this.setState({
										message: attribute.referencedKey.entityName + ": " + this.props.t('primaryKeyWithOnlyOneAttributeNeeded'),
										messageError: true,
										messageOpened: true,
										deleteConfirmationDialogOpened: false,
									}, () => this.props.context.hideActivityIndicator());
								}
								else {
									let subKeyAttribute = subPrimaryKeys[0].attributes[0];
									return attribute.referenceAttributeName + "{ " + subKeyAttribute.name + ", " + this.getLabelAttributesQueryString(model.entities[attribute.referencedKey.entityName], localModel.entities[attribute.referencedKey.entityName], subKeyAttribute) + "}";
								}
							}
							else {
								return attribute.name;
							}
						}) + " " +
						
						Object.keys(entityLocalModel.attributes)
								.filter(attributeName => entityLocalModel.attributes[attributeName].filteredWhenEmpty
										&& this.state.basicFilters[attributeName])
								.map(attributeName => attributeName + "(joinType: INNER limit: 1) {id}")
								.join(" ") +
						
					'	} ' +
					'}';

			const variables = {
	    		authorization: this.props.context.accessToken
	    	};
			let request = JSON.stringify({query: query, variables: variables});

			fetch(this.props.context.graphqlEndpoint, {
				method: "POST",
				body: request
			})
			.then(response => response.json())
			.then(json => {
				// This is considered an anti-pattern
				if (this._isMounted) {
					this.setState({
						keyAttribute: keyAttribute,
						currentEntity: this.props.entity,
						data: json.data["result"],
						attributes: attributes,
						searchEnabled: searchEnabled,
					}, () => this.props.context.hideActivityIndicator());
				}
				else {
					this.props.context.hideActivityIndicator();
				}
			})
			.catch(error => {
				console.log("!!!!! Retrying...");
				console.log(error);
				setTimeout(this.refreshDataThrottled(), 500);
			});
		}
	}
	
	// Render
	render() {
		// console.log(">> EntityList.render");
		
		// const { orderBy, orderDirection } = this.state;
		const { classes, t } = this.props;
		
		if (this.state != null 
				&& this.props.context.model !== null
				&& this.state.currentEntity === this.props.entity) {
			
			const selectedItems = this.state.data.filter(item => item._selected);
			const { orderBy, orderDirection } = this.state;
			
			const model = this.props.context.model;
			const entityLocalModel = localModel.entities[this.props.entity];
			const entityModel = model.entities[this.props.entity];
			
			let attributes = this.state.attributes;
			
			let localAttributes = Object.values(entityLocalModel.attributes);
			
			let calendarEnabled = localAttributes.filter(attribute => attribute.eventStart).length > 0;
			let mapEnabled = localAttributes.filter(attribute => attribute.pointInMap).length > 0;
			
			const columns = this.state.attributes.map(attribute => { return {
		    	header: t('e.' + this.props.entity + '.a.' + attribute.name),
		    	accessor: t('e.' + this.props.entity + '.a.' + attribute.name),
		    	dataType: ((attribute.type === "TEXT"
								|| attribute.type === "VARCHAR"
								|| attribute.type === "CHAR"
							) && "String")
						|| (attribute.type === "BOOLEAN"
							&& "Boolean")
						|| ((attribute.type === "INTEGER"
								|| attribute.type === "SERIAL"
								|| attribute.type === "SMALLINT"
								|| attribute.type === "BIGINT"
								|| attribute.type === "SMALLSERIAL"
								|| attribute.type === "BIGSERIAL"
								|| attribute.type === "DECIMAL"
								|| attribute.type === "MONEY"
								|| attribute.type === "DOUBLE_PRECISION"
								|| attribute.type === "REAL"
							) && "Number")
						|| ((attribute.type === "TIMESTAMP"
								|| attribute.type === "TIME"
								|| attribute.type === "INTERVAL"
								|| attribute.type === "TIMESTAMP_WITH_TIME_ZONE"
								|| attribute.type === "TIME_WITH_TIME_ZONE"
							) && "DateTime")
						|| (attribute.type === "DATE"
							&& "Date")
			}});
			
			return this.props.context.model !== null && (
				<>
					<Paper square>
						<Toolbar className={selectedItems.length === 0 ? classes.toolbar : classes.toolbar2}>
							{ selectedItems.length > 0 ? (
								<Typography variant="h6" className={classes.subheading} color="inherit" noWrap>{selectedItems.length} {t('selected')}</Typography>
							) : (
								<Typography variant="h6" className={classes.title} color="inherit" noWrap>{t('e.' + this.props.entity + '.pluralName')}</Typography>
							)}
							<div className={classes.spacer}/>
							{ selectedItems.length > 0 ? (
								<div>
									<Tooltip title={t('view')}>
										<span>
											<IconButton 
													aria-label={t('view')} 
													classes={{
														root: classes.action,
														disabled: classes.disabledAction,
													}}
													disabled={selectedItems.length !== 1}
													onClick={this.handleViewClick}>
												<DescriptionIcon/>
											</IconButton>
										</span>
									</Tooltip>
									{ (model.super || model.entities[this.props.entity].privileges["UPDATE"] !== undefined) && (
										<Tooltip title={t('edit')}>
											<span>
												<IconButton 
														aria-label={t('edit')}
														classes={{
															root: classes.action,
															disabled: classes.disabledAction,
														}}
														disabled={selectedItems.length !== 1}
														onClick={this.handleEditClick}>
													<EditIcon/>
												</IconButton>
											</span>
										</Tooltip>
									)}
									{ (model.super || model.entities[this.props.entity].privileges["DELETE"] !== undefined) && (
										<Tooltip title={t('delete')}>
											<span>
												<IconButton 
														aria-label={t('delete')} 
														classes={{
															root: classes.action,
															disabled: classes.disabledAction,
														}}
														onClick={this.handleDeleteClick}>
													<DeleteIcon/>
												</IconButton>
											</span>
										</Tooltip>
									)}
								</div>
							) : (
								<div className={classes.search}>

									{
										Object.keys(entityLocalModel.attributes)
												.filter(attributeName => entityLocalModel.attributes[attributeName].filteredWhenEmpty)
												.map(attributeName => (
														<FormControlLabel key={attributeName + "Filter"}
															control={
																	<Checkbox color="secondary" 
																		onChange={(event, checked) => this.handleCheckboxChange(event, checked, attributeName)} 
																		checked={this.state.basicFilters[attributeName]}
																	/>
															}
															label={t('e.' + this.props.entity + '.pluralName') + " " + t('with') + " " + t('e.' + this.props.entity.split("\.")[0] + "." + attributeName.split("ListVia")[0] + '.pluralName').toLowerCase()}/>
												))
									}
									
									{
										(calendarEnabled
											|| mapEnabled)
											&&
										<div className={classes.toggleContainer}>
											<ToggleButtonGroup selected value={this.state.mode} exclusive onChange={this.handleChangeMode}>
												<ToggleButton value="table">
													<Tooltip title={t('tableView')}>
														<ListIcon/>
													</Tooltip>
												</ToggleButton>
												
												{
													calendarEnabled
															&&
													<ToggleButton value="calendar">
														<Tooltip title={t('calendarView')}>
															<EventIcon/>
														</Tooltip>
													</ToggleButton>
												}
												{
													mapEnabled
															&&
													<ToggleButton value="map">
														<Tooltip title={t('mapView')}>
															<MapIcon/>
														</Tooltip>
													</ToggleButton>
												}
											</ToggleButtonGroup>
										</div>
									}
									
									{
										localAttributes.filter(attribute => attribute.basicFilter).length > 0
											&&
										<div className={classes.toggleContainer}>
											<Tooltip title={this.state.basicFiltersEnabled ? t('disableBasicFilters') : t('enableBasicFilters')}>
												<ToggleButtonGroup selected value={this.state.basicFiltersEnabled} exclusive onChange={this.handleToggleBasicFilters}>
													<ToggleButton value={true}>
														<FilterListIcon/>
													</ToggleButton>
												</ToggleButtonGroup>
											</Tooltip>
										</div>
									}
									
									{ this.state.searchEnabled && (
										<>
											<Input onKeyDown={this.handleSearchKeyDown} inputRef={this.searchInput} placeholder={t('search') + "..."} className={classes.input} autoFocus={this.props.main}/>
											<Tooltip title={t('search')}>
												<IconButton 
														aria-label={t('search')} 
														onClick={this.handleSearchClick}>
													<SearchIcon/>
												</IconButton>
											</Tooltip>
										</>
									)}
									
									{/*
									<Tooltip title={this.state.filtersEnabled ? t('disableFilters') : t('enableFilters')}>
										<span>
											<IconButton
													aria-label={this.state.filtersEnabled ? t('disableFilters') : t('enableFilters')} 
													onClick={this.handleEnableFilters}>
												{this.state.filtersEnabled ? <FilterListOutlinedIcon/> : <FilterListIcon/>}
											</IconButton>
										</span>
									</Tooltip>
									*/}
								</div>
							)}
							{
								(
									model.super || model.entities[this.props.entity].privileges["INSERT"] !== undefined) 
											&& ((this.props.main && 
										<Tooltip title={t('new')}>
											<Fab data-qa={this.props.entity + "-create-button"}
													onClick={this.handleNewClick}
													color="primary"
													className={classes.fab}
											>
												<AddIcon/>
											</Fab>
										</Tooltip>
									) || (
										<Tooltip title={t('new')}>
											<IconButton data-qa={this.props.entity + "-" + this.props.filterAttribute + "-create-button"}
													classes={{
														root: (selectedItems.length > 0 ? classes.action : ''),
													}}
													aria-label={t('new')} 
													onClick={this.handleNewClick}>
												<AddIcon/>
											</IconButton>
										</Tooltip>
									)
								)
							}
						</Toolbar>
					</Paper>
					{
						this.state.filtersEnabled && (
							<Paper square className={classes.root}>
								<LogiFilterBuilder
										header={t('searchFilter')}
										startExpanded={true}
										columns={columns}
										getFilterStatement={(filterStatement, conditions) => {
											//console.log(filterStatement);
											//console.log(conditions);
										}}
								/>
								<br/>
							</Paper>
						)
					}
					{
						this.state.basicFiltersEnabled && (
							<form onSubmit={
									(event) => {
										event.preventDefault(); 
										this.refreshData();
									}
								}>
							<Paper square className={classes.paper}>
								<Grid container spacing={24}>
									<Grid item xs={12}>
										<Typography variant="subtitle2" 
												className={classes.formGroup} 
												color="inherit" 
												noWrap>
											{t('searchCriteria')}
										</Typography>
									</Grid>

								{
									attributes
											.filter(attribute => entityLocalModel.attributes[attribute.name].basicFilter)
											.map(attribute => 
							
										// Todos los campos que no son referencias inversas
										(
											attribute.incomingReferenceAttributeName === undefined 
													&& 
											(
												// Campos de texto sencillos con un único valor
												(
													!attribute.array
															&& !attribute.rich
															&& (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR") 
															&& attribute.enumType === undefined 
															&& 
														<Grid key={attribute.name} item xs={12} sm={6}>
															<TextField
																	inputRef={this.basicFilterRefs[attribute.name]}
																	label={t('e.' + this.props.entity + '.a.' + attribute.name)}
																	fullWidth
																	InputLabelProps={{shrink: true}}
															/>
														</Grid>
												)
												||

												// Referencias a otras entidades, cardinalidad uno
												(
													!attribute.array
															&& attribute.referenceAttributeName !== undefined
															&&
														<Grid key={attribute.name} item xs={12} sm={6}>
															<AutoComplete
																	entityName={Object.values(entityModel.references).filter(reference => reference.name === attribute.name)[0].referencedKey.entityName}
																	asyncRef={this.basicFilterRefs[attribute.name]}
																	label={t('e.' + this.props.entity + '.a.' + attribute.name)}
															/>
														</Grid>
												)			
												||
												
												// Campos de tipo entero con un único valor
												// Campos de tipo numérico con decimales con un único valor
												(
													attribute.referenceAttributeName === undefined
															&& !attribute.array 
															&& (attribute.type === "INTEGER"
																	|| attribute.type === "SMALLINT"
																	|| attribute.type === "BIGINT"
																	|| attribute.type === "SERIAL"
																	|| attribute.type === "DECIMAL"
																	|| attribute.type === "DOUBLE_PRECISION"
																	|| attribute.type === "REAL"
																	|| attribute.type === "MONEY"
																	|| attribute.type === "SMALLSERIAL"
																	|| attribute.type === "BIGSERIAL") 
															&& 
														<Grid key={attribute.name} item xs={12} sm={6}>
															<Grid container spacing={24}>
																<Grid key={attribute.name + "From"} item xs={12} sm={6}>
																	<TextField 
																			inputRef={this.basicFilterRefs[attribute.name + "From"]}
																			label={t('e.' + this.props.entity + '.a.' + attribute.name) + " (Desde)"} 
																			fullWidth
																			inputProps={{min: attribute.min, max: attribute.max, step: attribute.step}}
																			InputLabelProps={{shrink: true}}
																			type="number"
																	/>
																</Grid>
																<Grid key={attribute.name + "To"} item xs={12} sm={6}>
																	<TextField 
																			inputRef={this.basicFilterRefs[attribute.name + "To"]}
																			label={t('e.' + this.props.entity + '.a.' + attribute.name) + " (Hasta)"} 
																			fullWidth
																			inputProps={{min: attribute.min, max: attribute.max, step: attribute.step}}
																			InputLabelProps={{shrink: true}}
																			type="number"
																	/>
																</Grid>
															</Grid>
														</Grid>
												) 
												||
												
												// Campos de tipo fecha con un único valor
												(
													!attribute.array 
															&& (attribute.type === "DATE" || attribute.type === "TIMESTAMP") 
															&& 
														<Grid key={attribute.name} item xs={12} sm={6}>
															<Grid container spacing={24}>
																<Grid key={attribute.name + "From"} item xs={12} sm={6}>
																	<TextField 
																			inputRef={this.basicFilterRefs[attribute.name + "From"]}
																			label={t('e.' + this.props.entity + '.a.' + attribute.name) + " (Desde)"} 
																			fullWidth
																			InputLabelProps={{shrink: true}}
																			type={(attribute.type === "DATE" ? "date" : "datetime-local")}
																	/>
																</Grid>
																<Grid key={attribute.name + "To"} item xs={12} sm={6}>
																	<TextField 
																			inputRef={this.basicFilterRefs[attribute.name + "To"]}
																			label={t('e.' + this.props.entity + '.a.' + attribute.name) + " (Hasta)"} 
																			fullWidth
																			InputLabelProps={{shrink: true}}
																			type={(attribute.type === "DATE" ? "date" : "datetime-local")}
																	/>
																</Grid>
															</Grid>
														</Grid>
												)
											)
										)
									)
								}
								</Grid>
								<IconButton
										style={{display: "none"}}
										type="submit"/>
							</Paper>
							</form>
						)
					}
					<Paper square className={classes.root}>
					{
						(this.state.mode === 'table' 
								&& ((this.state.data !== null && this.state.data.length > 0 
								&& 
							<Table className={classes.table}>
								<TableHead>
									<TableRow className={classes.tableHeader}>
										{
											(model.super || model.entities[this.props.entity].privileges["DELETE"] !== undefined)
													&&
												<TableCell padding="checkbox" key="checkbox" className={classes.checkbox}>
													<Checkbox
															indeterminate={selectedItems.length !== 0
																	&& selectedItems.length !== this.state.data.length}
															checked={selectedItems.length === this.state.data.length}
															onChange={(event, checked) => this.handleSelectAllClick(event, checked)}
													/>
												</TableCell>
										}
										{
											this.state.attributes.map(attribute =>
												(
													attribute.referenceAttributeName === undefined 
															&& (attribute.type === "TEXT"
																	|| attribute.type === "BOOLEAN"
																	|| attribute.type === "DATE"
																	|| attribute.type === "TIMESTAMP"
																	|| attribute.type === "VARCHAR"
																	|| attribute.type === "CHAR"
																	|| attribute.type === "TIME"
																	|| attribute.type === "INTERVAL"
																	|| attribute.type === "TIMESTAMP_WITH_TIME_ZONE"
																	|| attribute.type === "TIME_WITH_TIME_ZONE"
																	|| attribute.type === "INTEGER"
																	|| attribute.type === "SERIAL"
																	|| attribute.type === "SMALLINT"
																	|| attribute.type === "BIGINT"
																	|| attribute.type === "SMALLSERIAL"
																	|| attribute.type === "BIGSERIAL"
																	|| attribute.type === "DECIMAL"
																	|| attribute.type === "MONEY"
																	|| attribute.type === "DOUBLE_PRECISION"
																	|| attribute.type === "REAL")
															&& 
														<TableCell 
																key={attribute.name} 
																align={(attribute.type === 'INTEGER' || attribute.type === 'DECIMAL' || attribute.type === 'MONEY' || attribute.type === 'SERIAL' || attribute.type === 'SMALLINT' || attribute.type === 'BIGINT' || attribute.type === 'DOUBLE_PRECISION' || attribute.type === 'REAL' || attribute.type === 'SMALLSERIAL' || attribute.type === 'BIGSERIAL' ? "right" : "left")}
																sortDirection={orderBy === attribute.name ? orderDirection : false}>
															<TableSortLabel
																	active={orderBy === attribute.name}
																	direction={orderDirection}
																	onClick={(event) => this.handleSortClick(event, attribute.name)}>
																{t('e.' + this.props.entity + '.a.' + attribute.name)}
															</TableSortLabel>
														</TableCell>
												) 
												||
												<TableCell 
														key={attribute.referenceAttributeName}>
													{t('e.' + this.props.entity + '.a.' + attribute.name)}
												</TableCell>
											)
										}
									</TableRow>
								</TableHead>
								<TableBody>
									{
										this.state.data.map(item => {
	
											const isSelected = item._selected != null && item._selected;
											return (
												<TableRow
														hover
														onClick={event => this.handleRowClick(event, item)}
														role="checkbox"
														tabIndex={-1}
														key={item[this.state.keyAttribute.name]}
														className={classes.tableRow}>
	
													{
														(model.super || model.entities[this.props.entity].privileges["DELETE"] !== undefined)
																&&
															<TableCell padding="checkbox" key="checkbbox">
																<Checkbox 
																		checked={isSelected} 
																		onClick={event => this.handleCheckboxClick(event, item)}
																/>
															</TableCell>
													}
													{
														this.state.attributes.map(attribute => (
															attribute.referenceAttributeName === undefined 
																	&& 
																<TableCell 
																		key={attribute.name} 
																		align={(attribute.type === 'INTEGER' || attribute.type === 'DECIMAL' || attribute.type === 'MONEY' || attribute.type === 'SERIAL' || attribute.type === 'SMALLINT' || attribute.type === 'BIGINT' || attribute.type === 'DOUBLE_PRECISION' || attribute.type === 'REAL' || attribute.type === 'SMALLSERIAL' || attribute.type === 'BIGSERIAL' ? "right" : "left")}>
	
																	{
																		(
																			attribute.array 
																					&& (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR") 
																					&&
																				<div>{item[attribute.name] == null ? "" : item[attribute.name].join(", ")}</div>
																		)
																		||
																		(
																			!attribute.array
																					&& attribute.enumType != null
																					&& item[attribute.name] != null
																					&&
																				<div>{t('enums.' + attribute.enumType.schema + '.' + attribute.enumType.name + '.v.' + item[attribute.name])}</div>
																		)
																		||
																		(
																			!attribute.array
																					&& attribute.type === 'BOOLEAN'
																					&&
																				<Checkbox disabled checked={item[attribute.name]}></Checkbox>
																		)
																		||
																		(
																			!attribute.array
																					&& attribute.type === 'DATE'
																					&&
																				<div>{(item[attribute.name] == null ? "" : new Date(item[attribute.name]).toLocaleDateString())}</div>
																		)
																		||
																		(
																			!attribute.array
																					&& attribute.type === 'TIMESTAMP'
																					&&
																				<div>{(item[attribute.name] == null ? "" : new Date(item[attribute.name]).toLocaleString())}</div>
																		)
																		||
																		<div>{item[attribute.name]}</div>
																	}
																</TableCell>
															) 
															|| 
															(
																<TableCell key={attribute.referenceAttributeName}>
																	{
																		(
																			item[attribute.referenceAttributeName] != null 
																					&& 
																				// Le pongo el href, aunque no lo use, al menos para evitar el warning. Por otra parte, tampoco funcionaría porque recarga...
																				<a href={"not-used-but-required"} className={classes.anchor} onClick={event => this.handleAnchorClick(event, item, attribute)}>
																					{
																						item[attribute.referenceAttributeName] === null ? "" : this.getLabel(item[attribute.referenceAttributeName], model.entities[attribute.referencedKey.entityName], localModel.entities[attribute.referencedKey.entityName])
																					}
																				</a>
																		)
																	}
																</TableCell>
															)
														)
													}
												</TableRow>
											);
										})
									}
								</TableBody>
							</Table>
								) || (
							<Typography variant="subtitle1" className={classes.noData} color="inherit" noWrap>{t('noData')}</Typography>
								))
						)
						
						|| (this.state.mode === 'calendar' 
								&& 
							<div className={classes.calendar}>
					    		<BigCalendar
					    				className={classes.bigCalendar}
					    				culture={t('calendarCulture')}
					    				localizer={localizer}
					    				events={this.state.data}
					    				defaultView="week"
					    				views={["month", "week", "day"]}
					    				titleAccessor={"name"}
					    				allDayAccessor={() => localAttributes.filter(attribute => attribute.eventEnd).length == 0}
					    				startAccessor={(item) => (localAttributes.filter(attribute => attribute.eventStart).length > 0 ? new Date(item[localAttributes.filter(attribute => attribute.eventStart)[0].name]) : null)}
					    				endAccessor={(item) => (localAttributes.filter(attribute => attribute.eventEnd).length > 0 ? new Date(item[localAttributes.filter(attribute => attribute.eventEnd)[0].name]) : (localAttributes.filter(attribute => attribute.eventStart).length > 0 ? new Date(item[localAttributes.filter(attribute => attribute.eventStart)[0].name]) : null))}
					    				onSelectEvent={(item) => history.push("/admin/" + this.props.entity + '/' + item[this.state.keyAttribute.name] + "/view")}
					    				messages={{
					    					date: t('calendarDate'),
					    					time: t('calendarTime'),
					    					event: t('calendarEvent'),
					    					allDay: t('calendarAllDay'),
					    					week: t('calendarWeek'),
					    					work_week: t('calendarWorkWeek'),
					    					day: t('calendarDay'),
					    					month: t('calendarMonth'),
					    					previous: t('calendarPrevious'),
					    					next: t('calendarNext'),
					    					yesterday: t('calendarYesterday'),
					    					tomorrow: t('calendarTomorrow'),
					    					today: t('calendarToday'),
					    					agenda: t('calendarAgenda'),
					    				}}
					    		/>
					    	</div>
					    )
						
						|| (this.state.mode === 'map' 
								&& 
							<Map 
									zoom={12}
									center={[this.state.latitude, this.state.longitude]}
							>
								<TileLayer
										url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
								/>
					    	</Map>
						)
					}
					</Paper>
					
					<Dialog style={{zIndex: 10000}}
							open={this.state && this.state.deleteConfirmationDialogOpened}
							onClose={event => this.setState({deleteConfirmationDialogOpened: false})}>
						<DialogTitle>{t('deleteConfirmation')}</DialogTitle>
						<DialogContent>
							<DialogContentText>{t('deleteQuestion') + " " + selectedItems.length + " " + t('selected') + "?"}</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={event => this.setState({deleteConfirmationDialogOpened: false})}>{t('cancel')}</Button>
							&nbsp;
							<Button color="primary" onClick={this.handleDeleteDebounced}>{t('delete')}</Button>
						</DialogActions>
					</Dialog>
					
					<Snackbar
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							autoHideDuration={5000}
							onClose={event => this.setState({ messageOpened: false })}
							open={this.state && this.state.messageOpened}>
						<SnackbarContent
								className={this.state.messageError ? classes.snackbarError : classes.snackbar}
								message={<><span className={classes.message}>{this.state.messageError && <ErrorIcon className={classes.icon}/>}{this.state.message}</span></>}
						/>
					</Snackbar>
					
					{
						//<div id="modeler-container" style={{height: "1000px", width: "100%", backgroundColor: "yellow"}}></div>
					}
				</>
			);
		}
		else if (this.state.messageError) {
			return (
			<Snackbar
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					autoHideDuration={5000}
					onClose={event => this.setState({ messageOpened: false })}
					open={this.state && this.state.messageOpened}>
				<SnackbarContent
						className={this.state.messageError ? classes.snackbarError : classes.snackbar}
						message={<><span className={classes.message}>{this.state.messageError && <ErrorIcon className={classes.icon}/>}{this.state.message}</span></>}
				/>
			</Snackbar>
			);
		}
		else {
			return null;
			
			/*
			return (
			<>
				<Paper square>
					<Toolbar className={classes.toolbar}>
						<Typography variant="h6" className={classes.title} color="inherit" noWrap>{t('e.' + this.props.entity + '.pluralName') + " (" + t('newRecord') + ")"}</Typography>
						<div className={classes.spacer}/>
					</Toolbar>
				</Paper>
				<Paper square><div id="modeler-container" style={{height: "100vh", width: "100%"}}></div></Paper>
			</>
			);
			*/
		}
	}
}

EntityList.propTypes = {
	context: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	entity: PropTypes.string.isRequired,
	mode: PropTypes.string, // "table", "calendar", "map"
	filterAttribute: PropTypes.string,
	filterValue: PropTypes.string,
};

export default withStyles(styles)(withContext(withI18n()(EntityList)));

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withContext } from './App';
import { withI18n } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Fab from '@material-ui/core/Fab';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
	spacer: {
		flex: "1 1",
	},
	div1: {
		padding: theme.spacing.unit * 2,
	},
	div2: {
		color: theme.palette.secondary.contrastText,
		backgroundColor: theme.palette.secondary.dark,
	},
	filenameText: {
		paddingTop: 0,
		paddingBottom: 10,
	},
	deleteBtn: {
		margin: theme.spacing.unit,
	},
	downloadBtn: {
		margin: theme.spacing.unit,
	},
	smallPreviewImg: {
		width: 'initial',
		minHeight: 100,
		maxHeight: 300,
		maxWidth: '80%',
		margin: 10,
		color: 'rgba(0, 0, 0, 0.87)',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
		boxSizing: 'border-box',
		boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
		borderRadius: 2,
		zIndex: 5,
		opacity: 1
	},
	imageContainer: {
		position: 'relative',
		zIndex: 10,
		textAlign: 'center',
	},
	action: {
		color: theme.palette.secondary.contrastText,
		"&$disabledAction": {
			color: theme.palette.secondary.light,
		}
	},
	actionToolbar: {
		flex: "0 0 auto",
	},
	disabledAction: {
	},
});

class PreviewList extends Component {

	constructor(props) {
		super(props);
		// console.log(">> EntityView.constructor");
	}
	
	render() {
		const { fileObjects, handleDownload, handleRemove, classes } = this.props;

		return (
			<Grid container spacing={8}>
				{
					fileObjects != null 
							&&
					fileObjects.map((fileObject, i) => {
						return (
							<Grid item xs={12} sm={12} key={i} className={classes.imageContainer}>
								<div className={classes.div1}>
									<div className={classes.div2}>
										<Toolbar className={classes.toolbar2}>
											<Typography variant="h6" className={classes.subheading} color="inherit" noWrap>{fileObject.name}</Typography>
											<div className={classes.spacer}/>
											<div className={classes.actionToolbar}>
												<Tooltip title={"Download"}>
													<span>
														<IconButton 
																aria-label="Download" 
																classes={{
																	root: classes.action,
																	disabled: classes.disabledAction,
																}}
																onClick={handleDownload(i)}>
															<CloudDownloadIcon/>
														</IconButton>
													</span>
												</Tooltip>
												<Tooltip title={"Delete"}>
													<span>
														<IconButton 
																disabled={this.props.disabled}
																aria-label="Delete" 
																classes={{
																	root: classes.action,
																	disabled: classes.disabledAction,
																}}
																onClick={handleRemove(i)}>
															<DeleteIcon/>
														</IconButton>
													</span>
												</Tooltip>
											</div>
										</Toolbar>
										{	
											(fileObject.name.endsWith(".jpg")
													|| fileObject.name.endsWith(".jpeg")
													|| fileObject.name.endsWith(".png")
													|| fileObject.name.endsWith(".gif")
													|| fileObject.name.endsWith(".bmp"))
												&&
											<img className={classes.smallPreviewImg} role="presentation" src={fileObject.data}/>									
										}
									</div>
								</div>
							</Grid>
						);
					})
				}
			</Grid>
		);
	}
}

export default withStyles(styles)(withContext(withI18n()(PreviewList)));
